import * as R from "ramda";
import React, { PropsWithChildren, useEffect } from "react";
import { Field, FormRenderProps } from "react-final-form";
import RAFChoiceOption from "../../../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDateTimePicker from "../../../../../RAFComponents/Inputs/RAFDateTimePicker";
import RAFDropdownCC from "../../../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm, {
  Condition,
  ConditionNotNullOrWhiteSpace,
} from "../../../../../RAFComponents/Inputs/RAFForm";
import RAFNumber from "../../../../../RAFComponents/Inputs/RAFNumber";
import RAFButtonComponent from "../../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../../../RAFComponents/Utility/RAFToastComponent";
import {
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  getTimeDifferenceInMinutes,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf,
} from "../../../../../RAFComponents/helpers/utils";
import { EvaluateScore } from "../../../../../RAFMaster/helpers/RMutils";
import { InvoiceTransactionPermissionConstants } from "../../../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  Constants,
  RAFButtonConstant,
  RAFLayout,
  RAFShiftActionStatus,
} from "../../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../../constants/Common/EntityConstants";
import { TimeSheetSummaryModuleName } from "../../../../ActiveContacts/ServiceTransaction/ServiceTimesheetSummary/ServiceTimesheetSummaryHelper";
import { ServiceTransactionRow } from "../../../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../../../Common/Providers/RAFObjectContextProvider";
import { InvoiceTransactionRow } from "./InvoiceTransactionRow";
import RAFLookUpMUI from "../../../../../RAFComponents/Inputs/RAFLookUpMUI";
import {
  getFormValue,
  setFormValue,
} from "../../../../../RAFComponents/Inputs/RFFUtils";
import {
  getServiceContractItemVariant,
  getTravelItemById,
} from "../../../CareServiceContractItem/CareServiceContractItemHelper";
import { ServiceContractItemRow } from "../../../CareServiceContractItem/ServiceContractItem";
import { TenantSettingsRow } from "../../../../Common/TenantSettings/TenantSettingsRow";
import RAFCheckBox from "../../../../../RAFComponents/Inputs/RAFCheckBox";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { CareEsioEntity } from "../../../../../constants/CareESIO/CareEsioConstants";
import RAFDetailsField from "../../../../../RAFComponents/Inputs/RAFDetailsField";
import { getUserTenantSettings } from "../../../../Common/TenantSettings/TenantSettingsHelper";

interface IProps {
  onDelete?: () => void;
  objectUID?: string;
  serviceTransaction?: ServiceTransactionRow;
}

interface IState {
  initialValues?: InvoiceTransactionRow;
  variantServiceContractItem?: ServiceContractItemRow;
  tenantSettings?: TenantSettingsRow;
}

function UpdateInvoiceShiftItem({
  objectUID,
  serviceTransaction,
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  let rafFormRef: FormRenderProps | null;

  const [state, setState] = React.useState<IState>({
    initialValues: null,
    tenantSettings: null,
    variantServiceContractItem: null,
  });

  useEffect(() => {
    refresh();
  }, [objectUID]);

  const refresh = async () => {
    if (IsNotNullOrWhiteSpace(objectUID)) {
      const [initialValues, tenantSettings] = await Promise.all([
        RetrieveRecord(objectUID, moduleName),
        getUserTenantSettings(),
      ]);

      setState({ ...state, initialValues, tenantSettings });
    }
  };

  const moduleName = TimeSheetSummaryModuleName.InvoiceTransaction;

  const onSubmitObject = async (
    values,
    entity,
    queryAttributes,
    saveObject: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string }>,
    initialValues: InvoiceTransactionRow
  ) => {
    let shiftUpdate: InvoiceTransactionRow = R.clone(values);
    let progressDiv = showProgress(`#raf_dlg_Outer_Div_${moduleName}`);

    const initialBillingStatus = initialValues.BillingStatus;
    const currentBillingStatus = shiftUpdate.BillingStatus;
    if (initialBillingStatus !== currentBillingStatus) {
      const serviceTransaction: ServiceTransactionRow = await RetrieveRecord(
        shiftUpdate.ServiceTransactionUID,
        RAFEntityName.ServiceTransaction
      );

      if (
        isNotNullAndUndefined(serviceTransaction) &&
        isNotNullAndUndefined(serviceTransaction.UID)
      ) {
        if (
          serviceTransaction.Status !== RAFShiftActionStatus.Unassigned &&
          serviceTransaction.Status !== RAFShiftActionStatus.Scheduled &&
          serviceTransaction.Status !== RAFShiftActionStatus.InProgress
        ) {
          if (shiftUpdate.BillingStatus === RAFShiftActionStatus.Ready) {
            if (shiftUpdate.Status === RAFShiftActionStatus.Approved) {
              shiftUpdate.BillingStatus = RAFShiftActionStatus.Approved;
            } else if (shiftUpdate.Status === RAFShiftActionStatus.Cancelled) {
              shiftUpdate.BillingStatus = RAFShiftActionStatus.Cancelled;
            }
            // else {
            //   hideProgress(progressDiv);
            //   showWarningToast(
            //     "Please approve the shift before sending invoice"
            //   );
            //   return;
            // }
          } else {
            shiftUpdate.BillingStatus = shiftUpdate.BillingStatus;
          }
        } else {
          hideProgress(progressDiv);
          showWarningToast(
            "Please complete this shift before updating billing status"
          );
          return;
        }
      }
    }

    if (
      isNotNullAndUndefined(entity) &&
      isNotNullAndUndefined(entity.EntitySettings) &&
      isNotNullAndUndefined(entity.EntitySettings.EnableScore) &&
      entity.EntitySettings.EnableScore === true
    ) {
      shiftUpdate = EvaluateScore(values, queryAttributes);
    }

    const duration = getTimeDifferenceInMinutes(
      shiftUpdate.StartDate,
      shiftUpdate.EndDate
    );
    shiftUpdate.Duration = duration;

    saveObject(shiftUpdate)
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.objectUID)
        ) {
          showSuccessToast("Saved successfully");
          hideProgress(progressDiv);
          if (props.onSave)
            props.onSave(response.objectUID, response.objectName);
        } else {
          showWarningToast(
            "Apologies, we're unable to update the record at the moment. Please try again later.!"
          );
        }
      })
      .catch((error) => error);
  };

  const isStartDateGreaterThanEndDate = (endDateFieldValue, allFormValue) => {
    let hasError = false,
      error = "";
    if (IsNotNullOrWhiteSpace(allFormValue)) {
      const startDateFieldValue = allFormValue["StartDate"];
      if (
        isNotNullAndUndefined(startDateFieldValue) &&
        IsNotNullOrWhiteSpace(endDateFieldValue)
      ) {
        const startDate = new Date(startDateFieldValue);
        const endDate = new Date(endDateFieldValue);

        if (
          isNotNullAndUndefined(startDate) &&
          isNotNullAndUndefined(endDate)
        ) {
          if (startDate > endDate) {
            hasError = true;
            error = "End date should be greater than start date";
          }
        }
      }
    }

    return hasError ? error : undefined;
  };

  const isEndDateLessThan24hrs = (endDateFieldValue, allFormValue) => {
    let hasError = false,
      error = "";
    if (IsNotNullOrWhiteSpace(allFormValue)) {
      const startDateFieldValue = allFormValue["StartDate"];
      if (
        isNotNullAndUndefined(startDateFieldValue) &&
        IsNotNullOrWhiteSpace(endDateFieldValue)
      ) {
        const startDate = new Date(startDateFieldValue);
        const endDate = new Date(endDateFieldValue);

        if (
          isNotNullAndUndefined(startDate) &&
          isNotNullAndUndefined(endDate)
        ) {
          if (getTimeDifferenceInMinutes(startDate, endDate) > 1440) {
            hasError = true;
            error = "End date should be within 24 hours from start date";
          }
        }
      }
    }

    return hasError ? error : undefined;
  };

  const getInputFormContent = () => {
    return (
      <div className="row gx-0 gy-3">
        <div className="col-md-12">
          <RAFDateTimePicker
            field={propertyOf<InvoiceTransactionRow>("StartDate")}
            label="Start Date"
            required
            interval={15}
            roundOff
          />
        </div>
        <div className="col-md-12">
          <RAFDateTimePicker
            field={propertyOf<InvoiceTransactionRow>("EndDate")}
            label="End Date"
            required
            interval={15}
            roundOff
            validators={[isStartDateGreaterThanEndDate, isEndDateLessThan24hrs]}
          />
        </div>
        {/* <div className="col-md-12">
          <RAFNumber
            field={propertyOf<InvoiceTransactionRow>("InvoiceAmount")}
            label="Invoice Amount"
            disabled
            decimalsPoints={2}
          />
        </div> */}
        <ConditionNotNullOrWhiteSpace
          when={propertyOf<InvoiceTransactionRow>("RelatedToUID")}
        >
          <>{getServiceContractItemTravelVariantLookup()}</>
        </ConditionNotNullOrWhiteSpace>
        <div className="col-md-12">
          <RAFNumber
            field={propertyOf<InvoiceTransactionRow>("Rate")}
            label="Rate"
            disabled={false}
            decimalsPoints={2}
          />
        </div>
        <div className="col-md-12">
          <RAFDropdownCC
            field={propertyOf<InvoiceTransactionRow>("BillingStatus")}
            label="Status"
            //required
            disabled
          >
            <RAFChoiceOption
              label={RAFShiftActionStatus.Ready}
              value={RAFShiftActionStatus.Ready}
            />
            <RAFChoiceOption
              label={RAFShiftActionStatus.AddedtoInvoice}
              value={RAFShiftActionStatus.AddedtoInvoice}
            />
            <RAFChoiceOption
              label={RAFShiftActionStatus.InvoicedDirectly}
              value={RAFShiftActionStatus.InvoicedDirectly}
            />
            <RAFChoiceOption
              label={RAFShiftActionStatus.InvoicedtoXero}
              value={RAFShiftActionStatus.InvoicedtoXero}
            />
          </RAFDropdownCC>
        </div>
      </div>
    );
  };

  const onSelectServiceContractItem = async (label, serviceContractItemUID) => {
    const startDate = getFormValue(rafFormRef, "StartDate");
    const endDate = getFormValue(rafFormRef, "EndDate");
    const shiftType = getFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("ShiftType")
    );
    if (IsNullOrWhiteSpace(startDate) && IsNullOrWhiteSpace(endDate)) {
      showWarningToast("Please select Start Date and End Date");
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceContractItem"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceContractItemUID"),
        null
      );
      return;
    }
    if (IsNullOrWhiteSpace(startDate)) {
      showWarningToast("Please select Start Date");
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceContractItem"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceContractItemUID"),
        null
      );
      return;
    }
    if (IsNullOrWhiteSpace(endDate)) {
      showWarningToast("Please select End Date");
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceContractItem"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceContractItemUID"),
        null
      );
      return;
    }

    if (
      getFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("EnableTravel")
      )
    ) {
    } else {
      updateVariantServiceContractItem(
        getFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("RelatedToUID")
        ),
        serviceContractItemUID,
        startDate,
        endDate,
        shiftType
      );
    }
  };

  const updateVariantServiceContractItem = async (
    clientUID: string,
    serviceContractItemUID,
    startDate: Date,
    endDate: Date,
    shiftType: string
  ) => {
    if (
      isNotNullAndUndefined(clientUID) &&
      isNotNullAndUndefined(serviceContractItemUID) &&
      isNotNullAndUndefined(startDate) &&
      isNotNullAndUndefined(endDate)
    ) {
      // const [variantServiceContractItem, travelVariantLookUpItems] =
      //   await Promise.all([
      //     getServiceContractItemVariant(
      //       serviceContractItemUID,
      //       startDate,
      //       endDate,
      //       shiftType
      //     ),
      //     getAllTravelVariantLookUpItems(serviceContractItemUID, shiftType),
      //   ]);

      const variantServiceContractItem = await getServiceContractItemVariant(
        serviceContractItemUID,
        startDate,
        endDate,
        shiftType
      );

      if (isNotNullAndUndefined(variantServiceContractItem)) {
        showSuccessToast(
          "Time-specific line Item found for the selected Service Contract Item"
        );
        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("Variant"),
          variantServiceContractItem.Name
        );
        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("VariantUID"),
          variantServiceContractItem.UID
        );

        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("RateType"),
          variantServiceContractItem.RateType
        );

        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("Rate"),
          variantServiceContractItem.Rate
        );

        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("ServiceCode"),
          variantServiceContractItem.ServiceCode
        );
        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("ServiceName"),
          variantServiceContractItem.ServiceName
        );
        // if (isNotEmptyArray(travelVariantLookUpItems)) {
        //   const travelVariantLookUpItem = travelVariantLookUpItems[0];
        //   setFormValue(
        //     rafFormRef,
        //     propertyOf<InvoiceTransactionRow>("EnableTravel"),
        //     true
        //   );
        //   setFormValue(
        //     rafFormRef,
        //     propertyOf<InvoiceTransactionRow>("TravelVariant"),
        //     travelVariantLookUpItem.Value
        //   );
        //   setFormValue(
        //     rafFormRef,
        //     propertyOf<InvoiceTransactionRow>("TravelVariantUID"),
        //     travelVariantLookUpItem.UID
        //   );
        // } else {
        //   setFormValue(
        //     rafFormRef,
        //     propertyOf<InvoiceTransactionRow>("EnableTravel"),
        //     null
        //   );
        //   setFormValue(
        //     rafFormRef,
        //     propertyOf<InvoiceTransactionRow>("TravelVariant"),
        //     null
        //   );
        //   setFormValue(
        //     rafFormRef,
        //     propertyOf<InvoiceTransactionRow>("TravelVariantUID"),
        //     null
        //   );
        // }
        setState({ ...state, variantServiceContractItem });
      } else {
        showWarningToast(
          "No Time-specific line Item found for the selected Service Contract Item"
        );
        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("Variant"),
          null
        );
        setFormValue(
          rafFormRef,
          propertyOf<InvoiceTransactionRow>("VariantUID"),
          null
        );
        // setFormValue(
        //   rafFormRef,
        //   propertyOf<InvoiceTransactionRow>("TravelVariant"),
        //   null
        // );
        // setFormValue(
        //   rafFormRef,
        //   propertyOf<InvoiceTransactionRow>("TravelVariantUID"),
        //   null
        // );
        setState({ ...state, variantServiceContractItem: null });
      }
    } else {
      if (isNullOrUndefined(startDate) || isNullOrUndefined(endDate)) {
        showWarningToast("Please select Start Date and End Date");
      }
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("Variant"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("VariantUID"),
        null
      );
      // setFormValue(
      //   rafFormRef,
      //   propertyOf<InvoiceTransactionRow>("TravelVariant"),
      //   null
      // );
      // setFormValue(
      //   rafFormRef,
      //   propertyOf<InvoiceTransactionRow>("TravelVariantUID"),
      //   null
      // );
      // setFormValue(
      //   rafFormRef,
      //   propertyOf<InvoiceTransactionRow>("EnableTravel"),
      //   false
      // );
    }
  };

  const getServiceContractItemLookUp = () => {
    const { tenantSettings } = state;
    const isContractItemRequired = isNotNullAndUndefined(tenantSettings)
      ? tenantSettings.ContractItemRequired === "Yes"
        ? true
        : false
      : false;
    return (
      <Field name={propertyOf<InvoiceTransactionRow>("RelatedToUID")}>
        {({ input }) => {
          let customFilter: RAFCustomFilter = {};
          customFilter.Condition = "and";
          customFilter.Rules = [];
          if (isNotNullAndUndefined(input.value)) {
            let filter: RAFCustomFilter = {};
            let filterVal: string[] = [];
            filterVal.push(input.value);
            filter.Operator = RAFCustomOperator.Equal;
            filter.Value = filterVal;
            filter.Field = "CareRecipientUID";
            customFilter.Rules.push(filter);
          }

          let parentFilter: RAFCustomFilter = {};
          parentFilter.Operator = RAFCustomOperator.IsNull;
          parentFilter.Field = "ParentUID";
          customFilter.Rules.push(parentFilter);

          return (
            <RAFLookUpMUI<InvoiceTransactionRow>
              field={propertyOf<InvoiceTransactionRow>("ServiceContractItem")}
              label="Service Contract Item"
              placeholder="Select Service Contract Item"
              url="ServiceContractItem/Lookup"
              moduleName={CareEsioEntity.CareServiceContractItem.EntityName}
              customFilter={customFilter}
              showLabel
              onChanged={(label, value) =>
                onSelectServiceContractItem(label, value)
              }
              closeToolTip={false}
              showFullList={false}
              required={isContractItemRequired}
            />
          );
        }}
      </Field>
    );
  };

  const getServiceContractItemVariationLookUp = () => {
    return (
      <Field name={propertyOf<InvoiceTransactionRow>("ServiceContractItemUID")}>
        {({ input }) => {
          const serviceContractItemUID = IsNotNullOrWhiteSpace(input.value)
            ? input.value
            : null;
          let additionalParams = [];
          if (isNotNullAndUndefined(input.value)) {
            additionalParams = [
              {
                key: "ParentUID",
                value: serviceContractItemUID,
              },
            ];
          }

          return (
            <>
              <div className="col-12">
                <Field name={propertyOf<InvoiceTransactionRow>("ShiftType")}>
                  {({ input }) => {
                    let customFilter: RAFCustomFilter = {};
                    customFilter.Condition = "and";
                    customFilter.Rules = [];
                    if (isNotNullAndUndefined(input.value)) {
                      let filter: RAFCustomFilter = {};
                      let filterVal: string[] = [];
                      filterVal.push(input.value);
                      filter.Operator = RAFCustomOperator.Equal;
                      filter.Value = filterVal;
                      filter.Field = "ShiftType";
                      customFilter.Rules.push(filter);
                    }
                    return (
                      <RAFLookUpMUI<InvoiceTransactionRow>
                        field={propertyOf<InvoiceTransactionRow>("Variant")}
                        label="Time-specific line Item"
                        placeholder="Select Variations"
                        url="ServiceContractItem/VariantLookup"
                        //customFilter={customFilter}
                        moduleName={
                          CareEsioEntity.CareServiceContractItem.EntityName
                        }
                        additionalParams={additionalParams}
                        showLabel
                        closeToolTip={false}
                        showFullList={false}
                        // disabled={
                        //   IsNotNullOrWhiteSpace(serviceContractItemUID)
                        //     ? false
                        //     : true
                        // }
                      />
                    );
                  }}
                </Field>
                {IsNotNullOrWhiteSpace(serviceContractItemUID) && (
                  <div className="body_3_light mt-2">
                    <RAFDetailsField field="Variant" />
                  </div>
                )}
              </div>
              {/* <div className="col-12">
                <div className="custom__card__separator custom__card__separator__light"></div>
              </div> */}
            </>
          );
        }}
      </Field>
    );
  };

  const onChangeEnableTravel = (value) => {
    if (value === true) {
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("RateType"),
        "Kilometre"
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("Type"),
        "Travel"
      );
    } else {
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("Type"),
        serviceTransaction.Type
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("TravelDistance"),
        null
      );
    }

    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("TravelVariant"),
      null
    );
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("TravelVariantUID"),
      null
    );
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("Variant"),
      null
    );
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("VariantUID"),
      null
    );
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("ServiceContractItem"),
      null
    );
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("ServiceContractItemUID"),
      null
    );

    setFormValue(rafFormRef, propertyOf<InvoiceTransactionRow>("Rate"), null);

    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("ServiceCode"),
      null
    );
    setFormValue(
      rafFormRef,
      propertyOf<InvoiceTransactionRow>("ServiceName"),
      null
    );
  };

  const onSelectTravel = async (label, value) => {
    getTravelItem(value);
  };

  const getTravelItem = async (id) => {
    const travelItem = await getTravelItemById(id);
    if (isNotNullAndUndefined(travelItem)) {
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("Rate"),
        travelItem.Rate
      );

      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceCode"),
        travelItem.ServiceCode
      );
      setFormValue(
        rafFormRef,
        propertyOf<InvoiceTransactionRow>("ServiceName"),
        travelItem.ServiceName
      );
    }
  };

  const getServiceContractItemTravelVariantLookup = () => {
    return (
      <>
        <div className="col-12">
          <RAFCheckBox
            field={propertyOf<InvoiceTransactionRow>("EnableTravel")}
            label="Is Travel?"
            showLabel={true}
            uitype={"switch"}
            //labelPosition="left"
            //labelClassName="BpStpExtSet__customContent col"
            //inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
            onChanged={onChangeEnableTravel}
            labelClassName="col"
            inputFieldClassName="col-auto"
            colorStyle="secondary"
          />
        </div>
        <Condition
          when={propertyOf<InvoiceTransactionRow>("EnableTravel")}
          is={true}
        >
          {getServiceContractItemLookUp()}
          <div className="col-12">
            <Field
              name={propertyOf<InvoiceTransactionRow>("ServiceContractItemUID")}
            >
              {({ input }) => {
                const serviceContractItemUID = IsNotNullOrWhiteSpace(
                  input.value
                )
                  ? input.value
                  : null;
                let additionalParams = [];
                if (isNotNullAndUndefined(input.value)) {
                  additionalParams = [
                    {
                      key: "ParentUID",
                      value: serviceContractItemUID,
                    },
                  ];
                }

                return (
                  <div>
                    <Field
                      name={propertyOf<InvoiceTransactionRow>("ShiftType")}
                    >
                      {({ input }) => {
                        let customFilter: RAFCustomFilter = {};
                        customFilter.Condition = "and";
                        customFilter.Rules = [];
                        if (isNotNullAndUndefined(input.value)) {
                          let filter: RAFCustomFilter = {};
                          let filterVal: string[] = [];
                          filterVal.push(input.value);
                          filter.Operator = RAFCustomOperator.Equal;
                          filter.Value = filterVal;
                          filter.Field = "ShiftType";
                          customFilter.Rules.push(filter);
                        }
                        return (
                          <RAFLookUpMUI<InvoiceTransactionRow>
                            field={propertyOf<InvoiceTransactionRow>(
                              "TravelVariant"
                            )}
                            label="Travel Variant"
                            placeholder="Select Travel Variant"
                            url="ServiceContractItem/TravelVariantLookup"
                            customFilter={customFilter}
                            moduleName={
                              CareEsioEntity.CareServiceContractItem.EntityName
                            }
                            additionalParams={additionalParams}
                            showLabel
                            closeToolTip={false}
                            showFullList={false}
                            // disabled={
                            //   IsNotNullOrWhiteSpace(serviceContractItemUID)
                            //     ? false
                            //     : true
                            // }
                            onChanged={(label, value) => {
                              onSelectTravel(label, value);
                            }}
                          />
                        );
                      }}
                    </Field>
                    <div className="col-md-12">
                      <RAFNumber
                        field={propertyOf<InvoiceTransactionRow>(
                          "TravelDistance"
                        )}
                        label="Travel Distance"
                      />
                    </div>
                  </div>
                );
              }}
            </Field>
          </div>
        </Condition>
        <Condition when="EnableTravel" is={false}>
          <div className="col-12">
            {getServiceContractItemLookUp()}
            {getServiceContractItemVariationLookUp()}
          </div>
        </Condition>
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
      </>
    );
  };

  if (props.isActive) {
    return (
      <div
        className="h-100"
        key={moduleName}
        id={`raf_dlg_Outer_Div_${moduleName}`}
      >
        <RAFEntityProvider moduleName={moduleName}>
          <RAFAttributeRelatedListProvider moduleName={moduleName}>
            <RAFAttributesContext.Consumer>
              {({ queryAttributes }) => {
                return (
                  <RAFEntityContext.Consumer>
                    {({ entity, formLibrary }) => {
                      return (
                        <RAFObjectContextProvider
                          moduleName={moduleName}
                          //objectId={objectUID}
                          //progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                        >
                          <RAFObjectContext.Consumer>
                            {({ error, saveObject }) => {
                              // const initialValues =
                              //   isNotNullAndUndefined(rafObject) &&
                              //   isNotNullAndUndefined(rafObject.UID)
                              //     ? rafObject
                              //     : null;

                              const initialValues = state.initialValues;

                              if (isNotNullAndUndefined(initialValues)) {
                                return (
                                  <RAFForm
                                    initialValues={initialValues}
                                    formRef={(g) => (rafFormRef = g)}
                                    layout={RAFLayout.TwoColumnLayout}
                                    onSubmit={(values) =>
                                      onSubmitObject(
                                        values,
                                        entity,
                                        queryAttributes,
                                        saveObject,
                                        initialValues
                                      )
                                    }
                                    primaryKey={initialValues.UID}
                                    className="h-100"
                                  >
                                    <div className="e-dlg-content-outer">
                                      <div className="e-dlg-body-content">
                                        {getInputFormContent()}
                                      </div>
                                      <div className="e-dlg-footerContent">
                                        <div className="w-100">
                                          <div className="row gx-2">
                                            <div className="col-auto ms-auto">
                                              <RAFPermissionRender
                                                permissionName={
                                                  InvoiceTransactionPermissionConstants.InvoiceTransactionManageBilling
                                                }
                                              >
                                                <RAFButtonComponent
                                                  isPrimary
                                                  action={
                                                    RAFButtonConstant.Save
                                                  }
                                                  onClick={() =>
                                                    rafFormRef &&
                                                    rafFormRef.form.submit()
                                                  }
                                                  idString="EditContent"
                                                />
                                              </RAFPermissionRender>
                                            </div>
                                            <div className="col-auto">
                                              <RAFButtonComponent
                                                action={
                                                  RAFButtonConstant.Cancel
                                                }
                                                onClick={props.onClose}
                                                idString="EditContent"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </RAFForm>
                                );
                              } else {
                                if (error === Constants.RAFObject_NoContent) {
                                  return (
                                    <div className="container-fluid px-0">
                                      <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className="container-fluid px-0"
                                      style={{ background: "transparent" }}
                                    >
                                      {/* <ACLoadingPanel loadingText="Loading..." /> */}
                                    </div>
                                  );
                                }
                              }
                            }}
                          </RAFObjectContext.Consumer>
                        </RAFObjectContextProvider>
                      );
                    }}
                  </RAFEntityContext.Consumer>
                );
              }}
            </RAFAttributesContext.Consumer>
          </RAFAttributeRelatedListProvider>
        </RAFEntityProvider>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(UpdateInvoiceShiftItem);
