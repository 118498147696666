import { RAFRiskRegisterType } from "../Common/Constants";

export class CareEsioEntity {
  static Employee = {
    EntityName: "employee",
    DisplayName: "Employee",
    CollectionName: "Employee",
  };
  static EmployeeForm = {
    EntityName: "employee_profile",
    DisplayName: "Employee",
    CollectionName: "Employee",
  };

  static CareRecipient = {
    EntityName: "care_recipient",
    DisplayName: "Client",
    CollectionName: "Clients",
  };

  static care_recipient = this.CareRecipient;
  static CareShiftLog = {
    EntityName: "care_shift_log",
    DisplayName: "Shift Log",
    CollectionName: "Shift Log",
  };
  static CareTask = {
    EntityName: "care_task",
    DisplayName: "Task",
    CollectionName: "Support Tasks",
  };
  static CareAlert = {
    EntityName: "alert",
    DisplayName: "Alert",
    CollectionName: "Alerts",
  };
  static CareIncident = {
    EntityName: "care_incident",
    DisplayName: "Incident",
    CollectionName: "Incidents",
  };
  static CarePlan = {
    EntityName: "care_plan",
    DisplayName: "Support Plan",
    CollectionName: "Support Plan",
  };
  static CarePlanSection = {
    EntityName: "care_plan_section",
    DisplayName: "Care Plan Section",
    CollectionName: "Care Plan Sections",
  };
  static RiskRegister = {
    EntityName: "risk_register",
    DisplayName: RAFRiskRegisterType.Risk,
    CollectionName: "Risks",
  };
  static Assessment = {
    EntityName: "assessment",
    DisplayName: "Assessment",
    CollectionName: "Assessments",
  };
  // static CareAssessment = {
  //   EntityName: "care_assessment",
  //   DisplayName: "Assessment",
  //   CollectionName: "Assessments",
  // };
  static CareAssessment = {
    EntityName: "care_assessment",
    DisplayName: "Assessment",
    CollectionName: "Assessments",
  };
  static CareGoal = {
    EntityName: "care_goal",
    DisplayName: "Goal",
    CollectionName: "Goals",
  };
  static CareInterventionSupport = {
    EntityName: "care_intervention_support",
    DisplayName: "Support Need",
    CollectionName: "Support Needs",
  };
  static CareRecipientPreference = {
    EntityName: "care_recipient_preference",
    DisplayName: "Preference",
    CollectionName: "Preferences",
  };
  static CareRecipientTrigger = {
    EntityName: "care_recipient_trigger",
    DisplayName: "Behaviour",
    CollectionName: "Behaviours",
  };
  static CareRecipientAuditTimeLine = {
    EntityName: "audit",
    DisplayName: "History",
    CollectionName: "History",
  };
  static CareRecipientInbox = {
    EntityName: "inbox",
    DisplayName: "Inbox",
    CollectionName: "Inbox",
  };
  static CareRecipientMedication = {
    EntityName: "care_medication",
    DisplayName: "Medication",
    CollectionName: "Medications",
  };
  static CareMedicationLog = {
    EntityName: "care_medication_log",
    DisplayName: "Medication Log",
    CollectionName: "Medication Log",
  };
  static CareRecipientCQIRegister = {
    EntityName: "cqi_register",
    DisplayName: "CQI Register",
    CollectionName: "CQI Register",
  };
  static CareShiftActivity = {
    EntityName: "shift_activity",
    DisplayName: "Shift Activity",
    CollectionName: "Shift Activities",
  };
  static CareServiceContract = {
    EntityName: "service_contract",
    DisplayName: "Service & Agreement",
    CollectionName: "Services & Agreements",
  };
  static CareServiceContractItem = {
    EntityName: "service_contract_item",
    DisplayName: "Service Item",
    CollectionName: "Service Items",
  };
  static CarePriceListItem = {
    EntityName: "pricelist_item",
    DisplayName: "Line Item",
    CollectionName: "Line Items",
  };
  static ChecklistTemplateItem = {
    EntityName: "checklist_template_item",
    DisplayName: "Checklist Template Item",
    CollectionName: "Checklist Template Items",
  };
  static ChecklistTemplate = {
    EntityName: "checklist_template",
    DisplayName: "Checklist Template",
    CollectionName: "Checklist Templates",
  };
  static ChecklistItemTrans = {
    EntityName: "checklist_item_trans",
    DisplayName: "Checklist Item Trans",
    CollectionName: "Checklist Item Trans",
  };
  static CarePriceList = {
    EntityName: "pricelist",
    DisplayName: "Pricelist",
    CollectionName: "Pricelists",
  };
  static CarePayItem = {
    EntityName: "pay_item",
    DisplayName: "Pay Item",
    CollectionName: "Pay Items",
  };
  static CarePublicHolidays = {
    EntityName: "public_holiday",
    DisplayName: "Public Holiday",
    CollectionName: "Public Holidays",
  };
  static CareTeam = {
    EntityName: "care_team",
    DisplayName: "Care Team",
    CollectionName: "Care Team",
  };
  static CareNDISPriceGuide = {
    EntityName: "ndis_price_guide",
    DisplayName: "NDIS Price Guide",
    CollectionName: "NDIS Price Guides",
  };
  static CareNDISPriceGuideItem = {
    EntityName: "ndis_price_guide_item",
    DisplayName: "NDIS Price Guide Item",
    CollectionName: "NDIS Price Guide Items",
  };
  static CareCompleteShift = {
    EntityName: "complete_shift",
    DisplayName: "Complete Shift",
    CollectionName: "Complete Shifts",
  };
  static ClientNote = {
    EntityName: "client_note",
    DisplayName: "Note",
    CollectionName: "Notes",
  };
  static XeroIntegration = {
    EntityName: "XeroIntegration",
    DisplayName: "Integration",
    CollectionName: "Integrations",
  };
  static TimeChangeRequest = {
    EntityName: "time_change_request",
    DisplayName: "Clock In-Out Request",
    CollectionName: "Change Clock In-Out Requests",
  };
  static RecurringConfig = {
    EntityName: "recurring_config",
    DisplayName: "Form", // "Recurring Config",
    CollectionName: "Forms",
  };
  static InvoiceItem = {
    EntityName: "invoice_item",
    DisplayName: "Invoice Item",
    CollectionName: "Invoice Items",
  };
  static Invoice = {
    EntityName: "invoice",
    DisplayName: "Invoice",
    CollectionName: "Invoice",
  };
}

export class CareEsioTab {
  static ShiftLog = {
    Value: "ShiftLog",
    DisplayName: "Booking",
    CollectionName: "Bookings",
  };
  static MonitorCareHub = {
    Value: "CareHub",
    DisplayName: "Monitor",
    CollectionName: "Monitor",
  };
  static All = {
    Value: "All",
    DisplayName: "All",
    CollectionName: "All",
  };
  static PlannerOrganiser = {
    Value: "organiser",
    DisplayName: "Planner",
    CollectionName: "Planner",
  };
  static Calendar = {
    Value: "calendar",
    DisplayName: "Calendar",
    CollectionName: "Shift Calendar",
  };
  static Planner = {
    Value: "planner",
    DisplayName: "Planner",
    CollectionName: "Planner",
  };
  static Discussions = {
    Value: "discussions",
    DisplayName: "Feed",
    CollectionName: "Inbox",
  };
  static Feeds = {
    Value: "Feeds",
    DisplayName: "Feed",
    CollectionName: "All Feeds",
  };
  static Post = {
    Value: "Posts",
    DisplayName: "Post",
    CollectionName: "Posts",
  };
  static Billing = {
    Value: "Billing",
    DisplayName: "Invoices",
    CollectionName: "Invoices",
  };
  static Payroll = {
    Value: "Payroll",
    DisplayName: "Timesheet",
    CollectionName: "Timesheet",
  };
  static PermanentCondition = {
    Value: "PermanentCondition",
    DisplayName: "Conditions & Diagnosis",
    CollectionName: "Conditions & Diagnosis",
  };
}

export enum RAFCareEsioEventName {
  ApprovalClick = "ApprovalClick",
}

export enum RAFCareRecipientType {
  Client = "Client",
  Lead = "Lead",
  Prospect = "Prospect",
}

export class RAFCareRecipientCurrentStatus {
  static Active = {
    Value: "Active",
    DisplayName: "Active",
    ColorCode: "#1DB43E",
    LightColorCode: "#ECF9EE",
  };
  static InActive = {
    Value: "Inactive",
    DisplayName: "In active",
    ColorCode: "#FA4C4C",
    LightColorCode: "#FEE1E1",
  };
}
