import { SwipeableDrawer } from "@mui/material";
import { MenuEventArgs } from "@syncfusion/ej2-navigations";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent, DialogUtility } from "@syncfusion/ej2-react-popups";
import {
  DropDownButtonComponent,
  ItemModel,
} from "@syncfusion/ej2-react-splitbuttons";
import DOMPurify from "dompurify";
import moment from "moment";
import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useReducer,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { msalInstance } from "../../..";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import { getFormatedDate } from "../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecordRemoveFocus,
  IsSuperAdmin,
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import { hasPermission } from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IsNotNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  getCalculatedTimeDifferenceUserReadableText,
  getDate,
  hexToRGBA,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../RAFComponents/helpers/utils";
import {
  CarePlanPermissionConstants,
  InvoiceTransactionPermissionConstants,
  PayrollTransactionPermissionConstants,
  ServiceTransactionPermissionConstants,
  ServiceTransactionSupportLogPreviousLogPermissionConstants,
} from "../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  CareEsioEntity,
  CareEsioTab,
} from "../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  MomentFormats,
  RAFActionStatus,
  RAFButtonConstant,
  RAFShiftActionStatus,
  RAFStatusNameWithColor,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { NavigateParamsProps } from "../../../router";
import ServiceTransactionNotes from "../../ActiveContacts/ServiceTransaction/Details/ServiceTransactionNotes";
import ServiceTransactionProfileInfoHeader from "../../ActiveContacts/ServiceTransaction/Details/ServiceTransactionProfileInfoHeader";
import {
  RAFServiceTransactionShiftType,
  RAFServiceTransactionType,
  ServiceTransactionStates,
  getServiceTransactionDisplayNameFromStorage,
  saveServiceTransaction,
} from "../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import { ServiceTransactionRow } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import ServiceTransactionTimeChangeRequestContent from "../../ActiveContacts/ServiceTransaction/ServiceTransactionTimeChangeRequestContent";
import { getAllRelatedTasks } from "../../ActiveContacts/Task/TaskHelper";
import UserProfilePhoto from "../../ActiveContacts/User/UserProfilePhoto";
import { TenantSettingsRow } from "../../Common/TenantSettings/TenantSettingsRow";
import CareAuditList from "../AuditTimeLine/CareAuditDetails/CareAuditList";
import CarePlanDetails from "../CarePlan/CarePlanDetails";
import CareProfileContentDetails from "../CareRecipient/Details/CareProfileContentDetails";
import CareRecipientProfileInformation from "../CareRecipient/Details/CareRecipientProfileInformation";
import { atomSelectedRecipientRightContent } from "../CareRecipientHelper";
import ServiceTransactionPreviousLogPage from "../CareRegister/CareRegisterSupporLogReport/ServiceTransactionPreviousLogPage";
import InvoiceBillingListCardContent from "../CareShiftLog/CareBillingTimesheet/CareBilling/InvoiceBillingListCardContent";
import PayRollTimeSheetListCardContent from "../CareShiftLog/CareBillingTimesheet/TimeSheet/PayRollTimeSheetListCardContent";
import { getShiftActivitiesByTaskId } from "../CareShiftLog/CareShiftLogHelper";
import CareShiftLogTabContent from "../CareShiftLog/CareShiftLogTabContent";
import "./care_task_style.scss";

interface IProps {
  relatedToType: string;
  editClick?: (mode: "Edit" | "Manage") => void;
  rowClick?: () => void;
  manageClockActionClick?: (taskAction: "Clock In" | "Clock Out") => void;
  onClickCompleteBookingShift?: () => void;
  taskRow?: ServiceTransactionRow;
  hideCareShiftLogList?: boolean;
  isDashBoard?: boolean;

  careRecipientName?: string;
  careRecipientUID?: string;
  currentViewName?: "my_shift" | "all_shift" | "support_log";

  shiftType?: "Default" | "Billing" | "Payroll";

  selectedEntity?: string;
  isSupportLog?: boolean;
  allowSelection?: boolean;
  onDetailsClicked?: (id?: string) => void;
  onSelectedServiceTransaction?: (
    serviceTransactionRow?: ServiceTransactionRow,
    isChecked?: boolean
  ) => void;
  selectedServiceTransactions?: ServiceTransactionRow[];
  isDetailsMode?: boolean;
  showDetailsBtn?: boolean;
  showExpandCollapseBtn?: boolean;
  isListView?: boolean;
  onUpdateServiceTransaction?: () => void;
  careRecipientRow?: any;
  displayMode?: "dashboardWidget" | "homeWidget" | "default";
  showClientOverviewContent: boolean;
  onSave?: () => void;
  tenantSettings: TenantSettingsRow;
}

interface IState {
  isCollapsed: boolean;
  showShiftLogContent: boolean;
  selectedTabValue: any;
  showDrawerContent: boolean;
  showClientOverviewDlg: boolean;
}

function CareTaskSection({
  taskRow,
  showDetailsBtn = true,
  showExpandCollapseBtn = true,
  isDetailsMode = false,
  allowSelection = false,
  isListView = true,
  relatedToType = CareEsioEntity.CareRecipient.EntityName,
  ...props
}: PropsWithChildren<IProps & NavigateParamsProps>) {
  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const permissionName =
    taskRow.Status === RAFShiftActionStatus.InProgress
      ? ServiceTransactionPermissionConstants.ServiceTransactionDeleteShiftsAfterStart
      : ServiceTransactionPermissionConstants.ServiceTransactionDeleteShiftsBeforeStart;

  const hasPermissionToDelete = hasPermission(permissionValue, permissionName);

  const hasPermissionToEdit =
    (taskRow.Status === RAFShiftActionStatus.Scheduled ||
      taskRow.Status === RAFShiftActionStatus.Accepted ||
      taskRow.Status === RAFShiftActionStatus.OfferSent ||
      taskRow.Status === RAFShiftActionStatus.Unassigned) &&
    hasPermission(
      permissionValue,
      ServiceTransactionPermissionConstants.ServiceTransactionManageShifts
    )
      ? true
      : false;

  const allowEdit = IsSuperAdmin()
    ? true
    : hasPermissionToEdit || hasPermissionToDelete
    ? true
    : false;

  let headertext = [];

  if (props.isDashBoard && BrowserIsDevice) {
    headertext.push({
      text: "Client Profile",
      // permissionName: CarePlanPermissionConstants.CarePlanRead,
    });
  }
  if (props.isDashBoard) {
    headertext.push({
      text: CareEsioEntity.CarePlan.DisplayName,
      permissionName: CarePlanPermissionConstants.CarePlanRead,
    });
  }
  headertext.push(
    {
      text:
        taskRow.Type === RAFServiceTransactionType.Booking
          ? `${RAFServiceTransactionType.Booking} Log`
          : "Shift Log",
    },
    {
      //text: CareEsioTab.Billing.CollectionName,// commented by hari
      text: "Invoice Items",
      permissionName:
        InvoiceTransactionPermissionConstants.InvoiceTransactionViewBilling,
    },
    {
      text: CareEsioTab.Payroll.CollectionName,
      permissionName:
        PayrollTransactionPermissionConstants.PayrollTransactionViewTimeSheet,
    },
    {
      text: "Previous Log",
      permissionName:
        ServiceTransactionSupportLogPreviousLogPermissionConstants.ServiceTransactionSupportLogPreviousLog,
    }
  );

  const setSelectedRecipientRightContent = useSetRecoilState(
    atomSelectedRecipientRightContent(relatedToType)
  );

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isCollapsed: true,
      showShiftLogContent: isNotNullAndUndefined(isDetailsMode)
        ? isDetailsMode
        : false,
      selectedTabValue: {
        text:
          taskRow.Type === RAFServiceTransactionType.Booking
            ? `${RAFServiceTransactionType.Booking} Log`
            : "Shift Log",
      },
      showDrawerContent: false,
      showClientOverviewDlg: false,
    }
  );

  let navigate = useNavigate();
  let alertUtilityDialog: any;

  let checkBoxComponentRef = useRef(null);

  function editClick(mode: "Edit" | "Manage") {
    if (isNotNullAndUndefined(props.editClick)) {
      props.editClick(mode);
    }
  }

  // Function to handle checkbox change
  const onChangeCheckBoxValue = (
    serviceTransactionRow: ServiceTransactionRow
  ) => {
    if (isNotNullAndUndefined(props.onSelectedServiceTransaction)) {
      props.onSelectedServiceTransaction(
        serviceTransactionRow,
        checkBoxComponentRef.current.checked
      );
    }
  };

  const clockInContent = () => {
    const startedDate = convertUTCDateToLocalTimezone(taskRow.StartDate);
    const actualStartDate = convertUTCDateToLocalTimezone(
      taskRow.ActualStartDate
    );
    const recordedStartDate = convertUTCDateToLocalTimezone(
      taskRow.RecordedStartDate
    );

    let retVal;
    if (isNotNullAndUndefined(actualStartDate)) {
      if (moment(startedDate).isSame(actualStartDate, "day")) {
        // If the dates are the same, show the time only
        retVal = moment(actualStartDate).format(MomentFormats.TIME);
      } else {
        // If the dates are different, show both the date and time
        retVal = moment(actualStartDate).format("MMM DD, hh:mm A");
      }
    } else {
      retVal = "NA";
    }

    const difference = isNotNullAndUndefined(actualStartDate)
      ? getCalculatedTimeDifferenceUserReadableText(
          startedDate,
          recordedStartDate,
          actualStartDate
        )
      : null;

    const textToCheck = ["early", "On time"];
    const isEarly = isNotNullAndUndefined(difference)
      ? textToCheck.some((text) => difference.includes(text))
      : false;

    const clockInLabel =
      taskRow.Type === RAFServiceTransactionType.Booking ? "Start" : "Clock In";

    return (
      <div className="px-1">
        <div className="body_3_light medium my-1 text-nowrap d-flex">
          <span>{clockInLabel}</span>
          {IsNotNullOrWhiteSpace(difference) ? (
            <span
              className={
                isEarly
                  ? "text__success ecllipseFirstLine text-wrap word-break-all ms-1"
                  : "content_brand_secondary_dark ecllipseFirstLine text-wrap word-break-all ms-1"
              }
            >
              {`(${difference})`}
            </span>
          ) : (
            ""
          )}
          {/* {!isListView && isNotNullAndUndefined(difference) ? (
            <span className="content_brand_secondary_dark ecllipseFirstLine text-wrap word-break-all">
              {`(${difference})`}
            </span>
          ) : (
            ""
          )} */}
        </div>
        <div>
          <div className="row gx-1 align-items-center">
            <div className="col-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M9.92708 2.30016H6.07375C5.80708 2.30016 5.59375 2.08683 5.59375 1.82016C5.59375 1.5535 5.80708 1.3335 6.07375 1.3335H9.92708C10.1937 1.3335 10.4071 1.54683 10.4071 1.8135C10.4071 2.08016 10.1937 2.30016 9.92708 2.30016Z"
                  fill="#A57020"
                />
                <path
                  d="M13.3133 10H11.3533C10.5067 10 10 10.5067 10 11.3533V13.3133C10 14.16 10.5067 14.6667 11.3533 14.6667H13.3133C14.16 14.6667 14.6667 14.16 14.6667 13.3133V11.3533C14.6667 10.5067 14.16 10 13.3133 10ZM13.1267 12.9733L12.34 13.4267C12.18 13.52 12.02 13.5667 11.8733 13.5667C11.76 13.5667 11.66 13.54 11.5667 13.4867C11.3533 13.36 11.2333 13.1133 11.2333 12.7867V11.88C11.2333 11.5533 11.3533 11.3067 11.5667 11.18C11.78 11.0533 12.0533 11.08 12.34 11.24L13.1267 11.6933C13.4067 11.86 13.5667 12.0867 13.5667 12.3333C13.5667 12.58 13.4133 12.8067 13.1267 12.9733Z"
                  fill="#A57020"
                />
                <path
                  d="M9.33208 13.3134V11.3534C9.33208 10.1468 10.1454 9.33343 11.3521 9.33343H13.3121C13.4654 9.33343 13.6121 9.34676 13.7521 9.37343C13.7654 9.21343 13.7787 9.05343 13.7787 8.88676C13.7787 5.69343 11.1854 3.1001 7.99875 3.1001C4.81208 3.1001 2.21875 5.69343 2.21875 8.88676C2.21875 12.0734 4.81208 14.6668 7.99875 14.6668C8.56542 14.6668 9.10542 14.5734 9.62542 14.4268C9.43875 14.1134 9.33208 13.7401 9.33208 13.3134ZM8.49875 8.66676C8.49875 8.9401 8.27208 9.16676 7.99875 9.16676C7.72542 9.16676 7.49875 8.9401 7.49875 8.66676V5.33343C7.49875 5.0601 7.72542 4.83343 7.99875 4.83343C8.27208 4.83343 8.49875 5.0601 8.49875 5.33343V8.66676Z"
                  fill="#A57020"
                />
              </svg>
            </div>
            <div className="col-auto body_3">
              <span className="body_3 medium">{retVal ?? "NA"}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const clockOutContent = () => {
    const endDate = convertUTCDateToLocalTimezone(taskRow.EndDate);
    const actualEndDate = convertUTCDateToLocalTimezone(taskRow.ActualEndDate);
    const recordedEndDate = convertUTCDateToLocalTimezone(
      taskRow.RecordedEndDate
    );

    let retVal;
    if (isNotNullAndUndefined(actualEndDate)) {
      if (moment(endDate).isSame(actualEndDate, "day")) {
        // If the dates are the same, show the time only
        retVal = moment(actualEndDate).format(MomentFormats.TIME);
      } else {
        // If the dates are different, show both the date and time
        retVal = moment(actualEndDate).format("MMM DD, hh:mm A");
      }
    } else {
      retVal = "NA";
    }

    const difference = isNotNullAndUndefined(actualEndDate)
      ? getCalculatedTimeDifferenceUserReadableText(
          endDate,
          recordedEndDate,
          actualEndDate
        )
      : null;

    const textToCheck = ["early", "On time"];
    const isEarly = isNotNullAndUndefined(difference)
      ? textToCheck.some((text) => difference.includes(text))
      : false;

    const clockOutLabel =
      taskRow.Type === RAFServiceTransactionType.Booking ? "End" : "Clock Out";

    return (
      <div className="px-1">
        <div className="body_3_light medium my-1 text-nowrap d-flex">
          <span>{clockOutLabel}</span>
          {IsNotNullOrWhiteSpace(difference) ? (
            <span
              className={
                isEarly
                  ? "text__success ecllipseFirstLine text-wrap word-break-all ms-1"
                  : "content_brand_secondary_dark ecllipseFirstLine text-wrap word-break-all ms-1"
              }
            >
              {`(${difference})`}
            </span>
          ) : (
            ""
          )}
          {/* {!isListView && isNotNullAndUndefined(endDate_difference) ? (
            <span className="content_state_success_base ecllipseFirstLine text-wrap word-break-all">
              {`(${endDate_difference})`}
            </span>
          ) : (
            ""
          )} */}
        </div>
        <div>
          <div className="row gx-1 align-items-center">
            <div className="col-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.99875 3.1001C4.81208 3.1001 2.21875 5.69343 2.21875 8.8801C2.21875 12.0668 4.81208 14.6668 7.99875 14.6668C11.1854 14.6668 13.7787 12.0734 13.7787 8.88676C13.7787 5.7001 11.1854 3.1001 7.99875 3.1001ZM8.49875 8.66676C8.49875 8.9401 8.27208 9.16676 7.99875 9.16676C7.72542 9.16676 7.49875 8.9401 7.49875 8.66676V5.33343C7.49875 5.0601 7.72542 4.83343 7.99875 4.83343C8.27208 4.83343 8.49875 5.0601 8.49875 5.33343V8.66676Z"
                  fill="#A57020"
                />
                <path
                  d="M9.92708 2.30016H6.07375C5.80708 2.30016 5.59375 2.08683 5.59375 1.82016C5.59375 1.5535 5.80708 1.3335 6.07375 1.3335H9.92708C10.1937 1.3335 10.4071 1.54683 10.4071 1.8135C10.4071 2.08016 10.1937 2.30016 9.92708 2.30016Z"
                  fill="#A57020"
                />
              </svg>
            </div>
            <div className="col-auto body_3">
              <span className="body_3 medium">{retVal ?? "NA"}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const recordedStartDateContent = () => {
    const startedDate = convertUTCDateToLocalTimezone(taskRow.StartDate);
    const recordedStartDate = convertUTCDateToLocalTimezone(
      taskRow.RecordedStartDate
    );

    let retVal;
    if (isNotNullAndUndefined(recordedStartDate)) {
      if (moment(startedDate).isSame(recordedStartDate, "day")) {
        // If the dates are the same, show the time only
        retVal = moment(recordedStartDate).format(MomentFormats.TIME);
      } else {
        // If the dates are different, show both the date and time
        retVal = moment(recordedStartDate).format("MMM DD, hh:mm A");
      }
    } else {
      retVal = "NA";
    }
    return (
      <div className="px-1">
        <div className="body_3_light medium my-1 text-nowrap d-flex">
          <span className="ecllipseFirstLine text-wrap word-break-all">
            Recorded In (real-time)
          </span>
        </div>
        <div className="pt-1">
          <div className="row gx-1 align-items-center">
            <div className="col-auto body_3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.99875 3.1001C4.81208 3.1001 2.21875 5.69343 2.21875 8.8801C2.21875 12.0668 4.81208 14.6668 7.99875 14.6668C11.1854 14.6668 13.7787 12.0734 13.7787 8.88676C13.7787 5.7001 11.1854 3.1001 7.99875 3.1001ZM8.49875 8.66676C8.49875 8.9401 8.27208 9.16676 7.99875 9.16676C7.72542 9.16676 7.49875 8.9401 7.49875 8.66676V5.33343C7.49875 5.0601 7.72542 4.83343 7.99875 4.83343C8.27208 4.83343 8.49875 5.0601 8.49875 5.33343V8.66676Z"
                  fill="#357CA8"
                />
                <path
                  d="M9.92708 2.30016H6.07375C5.80708 2.30016 5.59375 2.08683 5.59375 1.82016C5.59375 1.5535 5.80708 1.3335 6.07375 1.3335H9.92708C10.1937 1.3335 10.4071 1.54683 10.4071 1.8135C10.4071 2.08016 10.1937 2.30016 9.92708 2.30016Z"
                  fill="#357CA8"
                />
              </svg>
            </div>
            <div className="col body_3">
              <span className="body_3 medium ecllipseFirstLine text-wrap word-break-all">
                {retVal ?? "NA"}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const recordedEndDateContent = () => {
    const EndDate = convertUTCDateToLocalTimezone(taskRow.EndDate);
    const recordedEndDate = convertUTCDateToLocalTimezone(
      taskRow.RecordedEndDate
    );

    let retVal;
    if (isNotNullAndUndefined(recordedEndDate)) {
      if (moment(EndDate).isSame(recordedEndDate, "day")) {
        // If the dates are the same, show the time only
        retVal = moment(recordedEndDate).format(MomentFormats.TIME);
      } else {
        // If the dates are different, show both the date and time
        retVal = moment(recordedEndDate).format("MMM DD, hh:mm A");
      }
    } else {
      retVal = "NA";
    }
    return (
      <div className="px-1">
        <div className="body_3_light medium my-1 text-nowrap d-flex">
          <span className="ecllipseFirstLine text-wrap word-break-all">
            Recorded Out (real-time)
          </span>
        </div>
        <div className="pt-1">
          <div className="row gx-1 align-items-center flex-nowrap">
            <div className="col-auto body_3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.99875 3.1001C4.81208 3.1001 2.21875 5.69343 2.21875 8.8801C2.21875 12.0668 4.81208 14.6668 7.99875 14.6668C11.1854 14.6668 13.7787 12.0734 13.7787 8.88676C13.7787 5.7001 11.1854 3.1001 7.99875 3.1001ZM8.49875 8.66676C8.49875 8.9401 8.27208 9.16676 7.99875 9.16676C7.72542 9.16676 7.49875 8.9401 7.49875 8.66676V5.33343C7.49875 5.0601 7.72542 4.83343 7.99875 4.83343C8.27208 4.83343 8.49875 5.0601 8.49875 5.33343V8.66676Z"
                  fill="#357CA8"
                />
                <path
                  d="M9.92708 2.30016H6.07375C5.80708 2.30016 5.59375 2.08683 5.59375 1.82016C5.59375 1.5535 5.80708 1.3335 6.07375 1.3335H9.92708C10.1937 1.3335 10.4071 1.54683 10.4071 1.8135C10.4071 2.08016 10.1937 2.30016 9.92708 2.30016Z"
                  fill="#357CA8"
                />
              </svg>
            </div>
            <div className="col body_3">
              <span className="body_3 medium ecllipseFirstLine text-wrap word-break-all">
                {retVal ?? "NA"}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const timeChangeRequestContent = (
    showlabel: boolean,
    labelClassName: string
  ) => {
    return (
      <ServiceTransactionTimeChangeRequestContent
        labelClassName={labelClassName}
        serviceTransactionRow={taskRow}
        showlabel={showlabel}
        onUpdateServiceTransaction={props.onUpdateServiceTransaction}
      />
    );
  };

  const TravelDistanceContent = (
    showLabel?: boolean,
    labelClassName?: string
  ) => {
    let showlabel = showLabel ?? true;
    if (showlabel) {
      return (
        <RAFDetailFieldCustom
          field="TravelDistance"
          title="Travel Distance"
          value={
            IsNotNullOrWhiteSpace(taskRow.TravelDistance) &&
            taskRow.TravelDistance !== 0
              ? `${taskRow.TravelDistance}KM`
              : `NA`
          }
          labelClassName={labelClassName ?? "subtitle_3"}
          rowClassName="gy-1 gx-0"
          showLabel={showlabel}
          {...(IsNotNullOrWhiteSpace(taskRow.TravelDistance) &&
          taskRow.TravelDistance !== 0
            ? {
                customValue: (
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center raf_badge raf_sm raf_badge_secondary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M9.70339 1.3335H6.30339C5.10339 1.3335 4.83672 1.9335 4.68339 2.6735L4.13672 5.28682H11.8701L11.3234 2.6735C11.1701 1.9335 10.9034 1.3335 9.70339 1.3335Z"
                          stroke="#F65C06"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.8283 9.54696C12.8817 10.1136 12.4283 10.6003 11.8483 10.6003H10.9417C10.4217 10.6003 10.3483 10.3803 10.255 10.1003L10.155 9.8136C10.0217 9.42027 9.935 9.15361 9.235 9.15361H6.76166C6.06833 9.15361 5.96166 9.4536 5.84166 9.8136L5.74166 10.1003C5.64833 10.3736 5.575 10.6003 5.055 10.6003H4.14833C3.56833 10.6003 3.115 10.1136 3.16833 9.54696L3.44167 6.6003C3.50833 5.87363 3.64833 5.28027 4.91499 5.28027H11.0817C12.3483 5.28027 12.4883 5.87363 12.555 6.6003L12.8283 9.54696Z"
                          stroke="#F65C06"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.1351 3.8335H3.64844"
                          stroke="#F65C06"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.3539 3.8335H11.8672"
                          stroke="#F65C06"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.10156 7.22021H6.54824"
                          stroke="#F65C06"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.45312 7.22021H10.8998"
                          stroke="#F65C06"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 11.3335V12.0002"
                          stroke="#F65C06"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 14V14.6667"
                          stroke="#F65C06"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1.9987 12L1.33203 14.6667"
                          stroke="#F65C06"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14 12L14.6667 14.6667"
                          stroke="#F65C06"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="ms-1 TravelDistanceContent">
                        {`${taskRow.TravelDistance}KM`}
                      </span>
                    </div>
                  </div>
                ),
              }
            : {})}
        ></RAFDetailFieldCustom>
      );
    } else {
      if (
        IsNotNullOrWhiteSpace(taskRow.TravelDistance) &&
        taskRow.TravelDistance !== 0
      ) {
        return (
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center raf_badge raf_sm raf_badge_secondary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M9.70339 1.3335H6.30339C5.10339 1.3335 4.83672 1.9335 4.68339 2.6735L4.13672 5.28682H11.8701L11.3234 2.6735C11.1701 1.9335 10.9034 1.3335 9.70339 1.3335Z"
                  stroke="#F65C06"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.8283 9.54696C12.8817 10.1136 12.4283 10.6003 11.8483 10.6003H10.9417C10.4217 10.6003 10.3483 10.3803 10.255 10.1003L10.155 9.8136C10.0217 9.42027 9.935 9.15361 9.235 9.15361H6.76166C6.06833 9.15361 5.96166 9.4536 5.84166 9.8136L5.74166 10.1003C5.64833 10.3736 5.575 10.6003 5.055 10.6003H4.14833C3.56833 10.6003 3.115 10.1136 3.16833 9.54696L3.44167 6.6003C3.50833 5.87363 3.64833 5.28027 4.91499 5.28027H11.0817C12.3483 5.28027 12.4883 5.87363 12.555 6.6003L12.8283 9.54696Z"
                  stroke="#F65C06"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.1351 3.8335H3.64844"
                  stroke="#F65C06"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.3539 3.8335H11.8672"
                  stroke="#F65C06"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.10156 7.22021H6.54824"
                  stroke="#F65C06"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.45312 7.22021H10.8998"
                  stroke="#F65C06"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 11.3335V12.0002"
                  stroke="#F65C06"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 14V14.6667"
                  stroke="#F65C06"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.9987 12L1.33203 14.6667"
                  stroke="#F65C06"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14 12L14.6667 14.6667"
                  stroke="#F65C06"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="ms-1 TravelDistanceContent">
                {`${taskRow.TravelDistance}KM`}
              </span>
            </div>
          </div>
        );
      }
    }
  };

  const notesRatingContent = (showLabel?: boolean) => {
    let showlabel = showLabel ?? true;
    return (
      <div>
        {showlabel ? (
          <span className="subtitle_3 medium mb-2">Notes rating</span>
        ) : (
          ""
        )}
        <div className="d-flex align-items-center">
          {IsNotNullOrWhiteSpace(taskRow.NotesRating) ? (
            <div className="raf_badge raf_sm raf_badge_warning_dark d-flex align-items-center flex-nowrap">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M5.33203 1.3335V3.3335"
                  stroke="#A57020"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.668 1.3335V3.3335"
                  stroke="#A57020"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14 5.66683V11.3335C14 13.3335 13 14.6668 10.6667 14.6668H5.33333C3 14.6668 2 13.3335 2 11.3335V5.66683C2 3.66683 3 2.3335 5.33333 2.3335H10.6667C13 2.3335 14 3.66683 14 5.66683Z"
                  stroke="#A57020"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.33203 7.3335H10.6654"
                  stroke="#A57020"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.33203 10.6665H7.9987"
                  stroke="#A57020"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="ms-1 ecllipseFirstLine word-break-all text-wrap raf_badge_text">
                {taskRow.NotesRating}
              </span>
            </div>
          ) : (
            <span className="details-value">NA</span>
          )}
        </div>
      </div>
    );
  };

  const careTaskSectionAssigneeStartEndContent = () => {
    return (
      <div className={`row g-sm-4 g-3`}>
        <div
          className={`overflow-auto profile_card_assignee_col${
            !isListView ? ` col-6 col-lg order-1 order-lg-0` : " col"
          }`}
        >
          <div className="px-1">
            <span className="subtitle_3 medium mb-2">Assignee</span>
            <div className="d-flex align-items-center">
              {IsNotNullOrWhiteSpace(taskRow.Assignee) ? (
                <UserProfilePhoto
                  uid={taskRow.AssigneeUID}
                  relatedToType={RAFEntityName.User}
                  canEdit={false}
                  profileImgCss={"profileImg-container-xsm"}
                  title={taskRow.Assignee ?? "Not set"}
                  iconSize="24"
                  fontSize="10"
                  emptyAvatarType="Initial"
                />
              ) : (
                <div className="e-avatar e-avatar-xsmall e-avatar-circle">
                  <div className="fas fa-sm fa-user"></div>
                </div>
              )}
              <span
                className={`ecllipseFirstLine text-decoration-underline profile_card_details_value ps-2
                  `}
              >
                {taskRow.Assignee ?? <i className="fas fa-xsm fa-minus"></i>}
              </span>
            </div>
          </div>
        </div>
        <div
          className={`overflow-auto profile_card_assignee_col${
            !isListView ? " col-6 col-lg order-1 order-lg-0" : " col"
          }`}
        >
          <span className="subtitle_3 medium mb-2">Status</span>
          <div className="d-flex align-items-center">
            {isNotNullAndUndefined(taskRow.State) &&
            taskRow.State === RAFStatusNameWithColor.Draft.DisplayName ? (
              <span
                className={`raf_badge raf_sm raf_badge_${
                  IsNotNullOrWhiteSpace(taskRow.State)
                    ? taskRow.State.toLowerCase()
                    : ""
                } text-nowrap`}
              >
                {taskRow.State}
              </span>
            ) : (
              <span
                className="raf_badge raf_sm ecllipseFirstline"
                style={{
                  backgroundColor: isNotNullAndUndefined(statusNameWithColor)
                    ? hexToRGBA(statusNameWithColor.Color, 0.1)
                    : "#333",
                  color: statusNameWithColor.Color,
                  border: `1px solid ${hexToRGBA(
                    statusNameWithColor.Color,
                    0.5
                  )}`,
                }}
              >
                {isNotNullAndUndefined(statusNameWithColor)
                  ? statusNameWithColor.DisplayName
                  : isNotNullAndUndefined(taskStatus)
                  ? taskStatus
                  : "Status"}
              </span>
            )}
          </div>
        </div>
        {taskRow.Type === RAFServiceTransactionType.Shift && (
          <>
            <div
              className={`overflow-auto profile_card_assignee_col${
                !isListView ? " col-6 col-lg order-1 order-lg-0" : " col"
              }`}
            >
              {notesRatingContent()}
            </div>
            {!isListView && (
              <>
                <div className="col-6 col-lg order-1 order-lg-0">
                  {timeChangeRequestContent(true, null)}
                </div>
                {/* <div className="col-6 col-lg-4 order-1 order-lg-0">
            {ActivitiesContent()}
          </div> */}
                <div className="col-6 col-lg order-1 order-lg-0">
                  {TravelDistanceContent()}
                </div>
              </>
            )}
          </>
        )}
        {taskRow.Type === RAFServiceTransactionType.Booking && (
          <>
            <div className="col-6 col-lg order-1 order-lg-0">
              {clockInContent()}
            </div>
            <div className="col-6 col-lg order-1 order-lg-0">
              {clockOutContent()}
            </div>
          </>
        )}
      </div>
    );
  };

  const careTaskStartAndEndTimeContent = (
    actualStartDateTime,
    actualEndDateTime
  ) => {
    return (
      <CustomCardWidget
        cardClassName={`${
          !isListView ? "surface_neutral_base " : "surface_neutral_light "
        }border-0`}
        cardContentClassName="p-2 p-md-3"
      >
        <div className={`row g-3${isListView ? " flex-nowrap" : ""}`}>
          {isListView ? (
            <>
              <div className="col col-lg">{clockInContent()}</div>
              <div className="col col-lg">{clockOutContent()}</div>
              {IsNotNullOrWhiteSpace(taskRow.TimeChangeRequestStatus) ? (
                <div className="col col-lg">
                  {timeChangeRequestContent(true, "body_3_light medium")}
                </div>
              ) : (
                ""
              )}
              {IsNotNullOrWhiteSpace(taskRow.TravelDistance) &&
              taskRow.TravelDistance !== 0 ? (
                <div className="col col-lg d-md-block d-none">
                  {TravelDistanceContent(true, "body_3_light medium")}
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <div className="col-6 col-lg-3">{clockInContent()}</div>
              <div className="col-6 col-lg-3">{clockOutContent()}</div>
              <div
                className={`col-6 col-lg-3${
                  isListView ? " d-none d-lg-block" : ""
                }`}
              >
                {recordedStartDateContent()}
              </div>
              <div
                className={`col-6 col-lg-3${
                  isListView ? " d-none d-lg-block" : ""
                }`}
              >
                {recordedEndDateContent()}
              </div>
            </>
          )}
        </div>
      </CustomCardWidget>
    );
  };

  const onClickPublishShift = async () => {
    if (isNotNullAndUndefined(taskRow.RelatedToUID)) {
      let progressDiv = showProgress("body");
      const serviceTransactionTemplateRow = R.clone(taskRow);
      serviceTransactionTemplateRow.State = ServiceTransactionStates.Published;
      const serviceTransactionResponse = await saveServiceTransaction(
        serviceTransactionTemplateRow
      );
      if (props.onUpdateServiceTransaction) {
        props.onUpdateServiceTransaction();
      }
      hideProgress(progressDiv);
    } else {
      showWarningToast("Please select a client to publish the shift.");
    }
  };

  const shiftBackArrowClicked = () => {
    if (!props.isDashBoard) {
      setSelectedRecipientRightContent(null);
    } else {
      navigate(-1);
    }
  };

  const onClickCompleteBookingShift = () => {
    if (props.onClickCompleteBookingShift) {
      props.onClickCompleteBookingShift();
    }
  };

  //action start
  const onClickClockActions = async (action: "Clock In" | "Clock Out") => {
    const hasPermissionToDoClockActions =
      taskRow.AssigneeUID === msalInstance.currentUserId
        ? true
        : hasPermission(
            permissionValue,
            ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsTeamShiftActions
          );

    if (!hasPermissionToDoClockActions) {
      showWarningToast(
        `Apologies, this shift is assigned to ${taskRow.Assignee}. You cannot ${action} a shift that is not assigned to you.`
      );
      return;
    }

    if (action === "Clock Out") {
      let progressDiv = showProgress("body");
      const [childShiftActivityItems, childTaskItems] = await Promise.all([
        getAllRelatedTasks(taskRow.RelatedToUID, taskRow.UID),
        getShiftActivitiesByTaskId(taskRow.UID),
      ]);
      hideProgress(progressDiv);
      const plannedShiftActivity = isNotEmptyArray(childShiftActivityItems)
        ? childShiftActivityItems.filter(
            (item: any) => item.Status == RAFActionStatus.Planned
          )
        : [];

      const plannedTaskItems =
        isNotEmptyArray(childTaskItems) &&
        isNotNullAndUndefined(taskRow.RelatedToUID) &&
        isNotNullAndUndefined(taskRow.UID)
          ? childTaskItems.filter(
              (item: any) => item.TaskStatus == RAFActionStatus.Planned
            )
          : [];

      if (isNotEmptyArray(plannedShiftActivity)) {
        showAlertContent(
          "Ensure completion of one or more scheduled support activities before clocking out of this shift."
        );
        return;
      } else if (isNotEmptyArray(plannedTaskItems)) {
        showAlertContent(
          "Ensure completion of one or more scheduled tasks or appointments before clocking out of this shift."
        );
        return;
      } else {
        if (props.manageClockActionClick) {
          props.manageClockActionClick(action);
        }
      }
    } else {
      if (props.manageClockActionClick) {
        props.manageClockActionClick(action);
      }
    }
  };

  const showAlertContent = (content: string) => {
    alertUtilityDialog = DialogUtility.alert({
      animationSettings: { effect: "Fade" },
      content: content,
      okButton: { text: "OK", click: alertOkButtonClick.bind(this) },
      title: "Alert",
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
      closeOnEscape: true,
      showCloseIcon: false,
    });
  };

  const alertOkButtonClick = () => {
    alertUtilityDialog.hide();
  };
  //action end

  //delete shift start
  let deleteDialog: any;

  const DeleteServiceTransactionClicked = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: DeleteServiceTransaction.bind(this) },
      showCloseIcon: false,
      title: `Delete ${getServiceTransactionDisplayNameFromStorage()}`,
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
    });
  };

  const DeleteServiceTransaction = async () => {
    let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");

    const serviceTransactionRow: ServiceTransactionRow = await RetrieveRecord(
      taskRow.UID,
      RAFEntityName.ServiceTransaction
    );

    if (
      isNotNullAndUndefined(serviceTransactionRow) &&
      isNotNullAndUndefined(serviceTransactionRow.UID)
    ) {
      const permissionName =
        serviceTransactionRow.Status === RAFShiftActionStatus.InProgress
          ? ServiceTransactionPermissionConstants.ServiceTransactionDeleteShiftsAfterStart
          : ServiceTransactionPermissionConstants.ServiceTransactionDeleteShiftsBeforeStart;

      const hasPermissionToDelete = IsSuperAdmin()
        ? true
        : hasPermission(permissionValue, permissionName);

      if (hasPermissionToDelete) {
        DeleteRecordRemoveFocus(
          taskRow.UID,
          RAFEntityName.ServiceTransaction
        ).then((response) => {
          deleteDialog.hide();
          hideProgress(progressDiv);
          if (isNotNullAndUndefined(response)) {
            shiftBackArrowClicked();
            // if (props.onDeleteServiceTransaction) {
            //   props.onDeleteServiceTransaction();
            // }
          }
        });
      } else {
        deleteDialog.hide();
        hideProgress(progressDiv);
        showWarningToast("You do not have permission to delete this shift");
      }
    } else {
      deleteDialog.hide();
      hideProgress(progressDiv);
      showWarningToast("Shift not found");
    }
  };

  //delete shift end

  const getShiftClockInActionBtn = () => {
    const taskStatus = taskRow.Status;
    const taskState = isNotNullAndUndefined(taskRow) ? taskRow.State : null;

    if (
      taskRow.Type === RAFServiceTransactionType.Booking &&
      (taskStatus === RAFShiftActionStatus.Scheduled ||
        taskStatus === RAFShiftActionStatus.Accepted)
    ) {
      return (
        <div className="col-auto" onClick={(e) => e.stopPropagation()}>
          <RAFButtonComponent
            action={RAFButtonConstant.Complete}
            isPrimary
            className="btn_state_success semi_dark"
            onClick={onClickCompleteBookingShift}
            showIcon={false}
          />
        </div>
      );
    } else if (
      taskRow.Type === RAFServiceTransactionType.Shift &&
      (taskStatus === RAFShiftActionStatus.Scheduled ||
        taskStatus === RAFShiftActionStatus.Accepted)
    ) {
      return (
        <div className="col-auto" onClick={(e) => e.stopPropagation()}>
          <RAFButtonComponent
            action={RAFButtonConstant.ClockIn}
            isPrimary
            className="btn_state_success semi_dark"
            onClick={() => {
              if (taskState === ServiceTransactionStates.Draft) {
                showWarningToast(
                  "You cannot start the shift until it is published."
                );
              } else if (taskState === ServiceTransactionStates.Published) {
                onClickClockActions("Clock In");
              }
            }}
            showIcon={false}
          />
        </div>
      );
    } else if (taskStatus === RAFShiftActionStatus.InProgress) {
      return (
        <div className="col-auto" onClick={(e) => e.stopPropagation()}>
          <RAFButtonComponent
            action={RAFButtonConstant.ClockOut}
            isPrimary
            className="btn_state_danger semi_dark"
            onClick={() => {
              onClickClockActions("Clock Out");
            }}
            showIcon={false}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const editBtnContent = (iconBtn) => {
    if (allowEdit) {
      return (
        <RAFButtonComponent
          action={RAFButtonConstant.Edit}
          isPrimary
          className="btn_state_warning"
          iconBtn={iconBtn}
          onClick={() => editClick("Edit")}
        ></RAFButtonComponent>
      );
    }
  };

  // const deleteBtnContent = (iconBtn) => {
  //   return(
  //     <RAFButtonComponent
  //               className={`btn_state_danger${iconBtn ? " transparent" : ""}`}
  //               action={RAFButtonConstant.Delete}
  //               iconBtn ={iconBtn}
  //               showIcon={false}
  //               onClick={DeleteServiceTransactionClicked}
  //             />
  //   )
  // }

  const shiftDetailsButtonContent = () => {
    const moreMenuItems: ItemModel[] = [];
    if (BrowserIsDevice) {
      moreMenuItems.push({
        id: "Edit",
        text: "Edit",
        iconCss: RAFButtonConstant.Edit.IconCss,
      });
      if (taskRow.Status === RAFShiftActionStatus.InProgress) {
        moreMenuItems.push({
          id: "ManageActivity",
          text: "Manage Activity",
          iconCss: RAFButtonConstant.Edit.IconCss,
        });
      }
    } else {
      if (taskRow.Status === RAFShiftActionStatus.InProgress) {
        moreMenuItems.push({
          id: "ManageActivity",
          text: "Manage Activity",
          iconCss: RAFButtonConstant.Edit.IconCss,
        });
      }
    }

    return (
      <div className="row gx-2">
        {BrowserIsDevice ? (
          <>
            {getShiftClockInActionBtn()}
            {state.showDrawerContent ? (
              isNotEmptyArray(moreMenuItems) && (
                <div className="col-auto" onClick={(e) => e.stopPropagation()}>
                  <DropDownButtonComponent
                    iconCss={RAFButtonConstant.More.IconCss}
                    className="bg-transparent border-0 e-caret-hide"
                    items={moreMenuItems}
                    select={onMoreMenuClicked.bind(this)}
                  />
                </div>
              )
            ) : (
              <div className="col-auto">
                <RAFButtonComponent
                  className="btn_brand_primary outline e-round"
                  action={RAFButtonConstant.View}
                  iconBtn
                  showIcon={false}
                  onClick={clientOverviewDlgOpen}
                />
                {/* <div className="mt-1 d-flex align-items-center justify-content-center" style={{width: '2rem'}}>
                  <i className="fal fa-chevron-square-down content_brand_primary_dark fa-md"></i>
                </div> */}
              </div>
            )}
            {/* {isNotEmptyArray(moreMenuItems) && (
              <div className="col-auto"
                onClick={(e) => e.stopPropagation()}
              >
                <DropDownButtonComponent
                  iconCss={RAFButtonConstant.More.IconCss}
                  className="bg-transparent border-0 e-caret-hide"
                  items={moreMenuItems}
                  select={onMoreMenuClicked.bind(this)}
                />
              </div>
            )} */}
          </>
        ) : (
          <>
            {taskRow.State === ServiceTransactionStates.Draft && (
              <div className="col-auto">
                <RAFButtonComponent
                  action={RAFButtonConstant.Publish}
                  btnContent="Publish Shift"
                  className="btn_brand_primary semi_dark"
                  onClick={onClickPublishShift}
                ></RAFButtonComponent>
              </div>
            )}
            {getShiftClockInActionBtn()}
            {allowEdit && (
              <div className="col-auto">{editBtnContent(true)}</div>
            )}
            {/* {allowEdit && (
              <div className="col-auto">{deleteBtnContent(true)}</div>
            )} */}
            {taskRow.Status === RAFShiftActionStatus.InProgress && (
              <div className="col-auto">
                <DropDownButtonComponent
                  iconCss={RAFButtonConstant.More.IconCss}
                  className="bg-transparent border-0 e-caret-hide"
                  items={moreMenuItems}
                  select={onMoreMenuClicked.bind(this)}
                ></DropDownButtonComponent>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const careTaskTitleSection = () => {
    const isItemSelected = isNotEmptyArray(props.selectedServiceTransactions)
      ? props.selectedServiceTransactions.some((x) => x.UID === taskRow.UID)
      : false;
    let taskDateValue;
    let taskDayValue;
    let taskMonthValue;
    let taskTitle;
    if (
      isNotNullAndUndefined(taskRow.StartDate) &&
      isNotNullAndUndefined(taskRow.EndDate)
    ) {
      taskDateValue = getFormatedDate(taskRow.StartDate, "DD");
      taskMonthValue = getFormatedDate(taskRow.StartDate, "MMM");
      // taskDayValue = getFormatedDate(taskRow.StartDate, "ddd");
      taskTitle =
        getFormatedDate(taskRow.StartDate, "h:mm a") +
        " - " +
        getFormatedDate(taskRow.EndDate, "h:mm a");

      const objTaskDate: Date = convertUTCDateToLocalTimezone(
        taskRow.StartDate
      );
      taskDayValue = getDate(objTaskDate, "ddd");
      if (
        taskDayValue !== "Today" &&
        taskDayValue !== "Yesterday" &&
        taskDayValue !== "Tomorrow"
      ) {
        taskDayValue = getFormatedDate(objTaskDate, "dddd");
      }
    }

    const plannedStartDateTime = getFormatedDate(taskRow.StartDate, "hh:mm a");
    const plannedEndDateTime = getFormatedDate(taskRow.EndDate, "hh:mm a");
    return (
      <div className="col-12">
        <div className="row g-3 align-items-center">
          {isListView ? (
            <>
              {allowSelection && !BrowserIsDevice && (
                <div
                  className={`col-auto${
                    isItemSelected ? "" : " hover-child-div-flex default_hidden"
                  }`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="profileImg-container-xmd d-flex align-items-center justify-content-center">
                    <CheckBoxComponent
                      ref={checkBoxComponentRef}
                      name={"UID"}
                      value={taskRow.UID}
                      // cssClass="e-checkbox-light e-checkbox-lg raf-round show-check-icon"
                      cssClass="e-checkbox-light"
                      change={() => onChangeCheckBoxValue(taskRow)}
                      checked={isItemSelected ? true : false}
                    />
                  </div>
                </div>
              )}
              {!isItemSelected && (
                <div
                  className={`col-auto${
                    allowSelection && !BrowserIsDevice
                      ? " hide-child-div-flex"
                      : ""
                  }`}
                >
                  <UserProfilePhoto
                    uid={taskRow.RelatedToUID}
                    relatedToType={taskRow.RelatedToType}
                    canEdit={false}
                    profileImgCss={"profileImg-container-xmd"}
                    title={taskRow.RelatedTo ?? "Not Set"}
                    iconSize="48"
                    fontSize="20"
                    emptyAvatarType="Initial"
                  />
                </div>
              )}
            </>
          ) : (
            !BrowserIsDevice && (
              <div className="col-auto">
                <div
                  className="backArrowIcon backArrowIcon-md"
                  onClick={shiftBackArrowClicked}
                >
                  <i className="fass fa-arrow-left-long"></i>
                </div>
              </div>
            )
          )}
          <div className="col">
            <div className="d-flex flex-column align-items-start">
              <div className="d-flex align-items-center">
                <span className={`header_6 semi_bold mb-1 ecllipseFirstLine`}>
                  {taskRow.RelatedTo ?? "Not Set"}
                  {/* {!isListView
                  ? "Shift Details"
                  : taskRow.RelatedTo ?? "Not Set"} */}
                  {/* {props.isDashBoard === true ? (taskRow.RelatedTo ?? "Not Set") : (taskRow.Assignee ?? "Not Set")} */}
                </span>
              </div>
              <div>
                <span className="header-text-sm">{`${taskMonthValue} ${taskDateValue},`}</span>
                <span className="header-text-sm-light ps-1">{`${plannedStartDateTime} - ${plannedEndDateTime}`}</span>
                {taskRow.ShiftType ===
                  RAFServiceTransactionShiftType.Passive && (
                  <span className="far fa-bed-front ps-2 content_brand_primary_dark fa-sm"></span>
                )}
              </div>
            </div>
          </div>
          {!isListView ? (
            <>
              {!isListView && !BrowserIsDevice ? (
                <div className="col-auto">
                  <div className="pe-2">
                    <RAFRecordInfo
                      createdBy={taskRow.CreatedBy}
                      createdDate={taskRow.CreatedDate}
                      modifiedDate={taskRow.ModifiedDate}
                      modifiedBy={taskRow.ModifiedBy}
                      lastActivityDate={taskRow.LastActivityDate}
                      {...(BrowserIsDevice
                        ? { hideClockIcon: false }
                        : { content: "Updated", hideClockIcon: true })}
                      dropdownBtnClassName="custom-button-sm p-0"
                      spanContentClassName="recordInfo_text bold"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-auto">{shiftDetailsButtonContent()}</div>
            </>
          ) : (
            <>
              {props.displayMode !== "homeWidget" ? (
                <div className={`col-xxl-6 d-none d-xxl-block`}>
                  {careTaskSectionAssigneeStartEndContent()}
                </div>
              ) : (
                ""
              )}
              {props.isDashBoard ? (
                <div className="col-auto">
                  <RAFButtonComponent
                    btnContent="View Details"
                    className="btn_brand_primary outline e-round"
                    action={RAFButtonConstant.View}
                    iconBtn
                    onClick={onDetailsClicked}
                  />
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  function careTaskSectionCustomTitle() {
    // const taskDisplayDateAndBadge = getTaskDisplayDateAndBadge(
    //   isNotNullAndUndefined(taskRow) ? taskRow.StartDate : null
    // );
    //const dateBadge = taskDisplayDateAndBadge.dateBadge;
    //const taskDate = taskDisplayDateAndBadge.taskDate;
    let taskTitle;
    let taskDateValue;
    let taskDayValue;
    let taskMonthValue;
    if (
      isNotNullAndUndefined(taskRow.StartDate) &&
      isNotNullAndUndefined(taskRow.EndDate)
    ) {
      taskDateValue = getFormatedDate(taskRow.StartDate, "DD");
      taskMonthValue = getFormatedDate(taskRow.StartDate, "MMM");
      // taskDayValue = getFormatedDate(taskRow.StartDate, "ddd");
      taskTitle =
        getFormatedDate(taskRow.StartDate, "h:mm a") +
        " - " +
        getFormatedDate(taskRow.EndDate, "h:mm a");

      const objTaskDate: Date = convertUTCDateToLocalTimezone(
        taskRow.StartDate
      );
      taskDayValue = getDate(objTaskDate, "ddd");
      if (
        taskDayValue !== "Today" &&
        taskDayValue !== "Yesterday" &&
        taskDayValue !== "Tomorrow"
      ) {
        taskDayValue = getFormatedDate(objTaskDate, "dddd");
      }
    }

    const plannedStartDateTime = getFormatedDate(taskRow.StartDate, "hh:mm a");
    const plannedEndDateTime = getFormatedDate(taskRow.EndDate, "hh:mm a");
    const actualStartDateTime = getFormatedDate(
      taskRow.ActualStartDate,
      "hh:mm a"
    );
    const actualEndDateTime = getFormatedDate(taskRow.ActualEndDate, "hh:mm a");

    return (
      <div id={"service_transaction" + taskRow.UID}>
        <div className="row gx-0 gy-2 gy-md-3">
          {careTaskTitleSection()}
          {props.displayMode === "homeWidget" && !isListView ? (
            ""
          ) : (
            <>
              <div className="col-12">
                <div className="custom__card__separator custom__card__separator__light"></div>
              </div>
              {props.showClientOverviewContent ? (
                <div className={"col-12"}>
                  <div className="row gx-0 gy-2 gy-md-3">
                    {!isListView ? (
                      <>
                        <div className="col-12">
                          <CareRecipientProfileInformation
                            careRecipientUID={taskRow.RelatedToUID}
                            selectedTabItem={{
                              text: "Overview",
                              value: "CareInfo",
                            }}
                            isdashboard={props.isDashBoard}
                            cardStyle={"SimpleContent"}
                            recipientObject={props.careRecipientRow}
                          />
                        </div>
                        <div className="col-12">
                          <div className="custom__card__separator custom__card__separator__light"></div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="col-12">
                      {careTaskSectionAssigneeStartEndContent()}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`col-12${
                    isListView && props.displayMode !== "homeWidget"
                      ? " d-xxl-none"
                      : ""
                  }`}
                >
                  {careTaskSectionAssigneeStartEndContent()}
                </div>
              )}
            </>
          )}
          {taskRow.Type === RAFServiceTransactionType.Shift && (
            <div className="col-12">
              {careTaskStartAndEndTimeContent(
                actualStartDateTime,
                actualEndDateTime
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  const taskStatus = isNotNullAndUndefined(taskRow)
    ? props.shiftType === "Billing"
      ? taskRow.BillingStatus
      : taskRow.Status
    : null;

  const onMoreMenuClicked = (args: MenuEventArgs) => {
    if (
      isNotNullAndUndefined(args) &&
      isNotNullAndUndefined(args.item) &&
      isNotNullAndUndefined(args.item.id)
    ) {
      if (args.item.id === "Edit") {
        editClick("Edit");
      } else if (args.item.id === "ManageActivity") {
        editClick("Manage");
      } else if (args.item.id === "PublishShift") {
        onClickPublishShift();
      }
    }
  };

  const onDetailsClicked = () => {
    let UID = taskRow.UID;
    if (props.isDashBoard) {
      navigate(`/bookings/view/${UID}`, {
        state: { currentViewName: props.currentViewName },
      });
    } else {
      //setState({ showShiftLogContent: !state.showShiftLogContent });
      if (isNotNullAndUndefined(props.onDetailsClicked)) {
        props.onDetailsClicked(taskRow.UID);
      }
    }
  };

  const careShiftLogTabContent = () => {
    return (
      <CareShiftLogTabContent
        careRecipientUID={props.careRecipientUID}
        careRecipientName={props.careRecipientName}
        selectedEntity={props.selectedEntity}
        hideCareShiftLogList={props.hideCareShiftLogList}
        isSupportLog={props.isSupportLog}
        manageClockActionClick={props.manageClockActionClick}
        onSave={props.onSave}
        shiftType={props.shiftType}
        showShiftLogContent={state.showShiftLogContent}
        tenantSettings={props.tenantSettings}
        taskRow={taskRow}
      />
    );
  };

  const statusNameWithColor =
    isNotNullAndUndefined(taskStatus) &&
    isNotNullAndUndefined(RAFStatusNameWithColor[taskStatus])
      ? RAFStatusNameWithColor[taskStatus]
      : null;

  const timeChangeRequestStatusNameWithColor =
    isNotNullAndUndefined(taskRow.TimeChangeRequestStatus) &&
    isNotNullAndUndefined(
      RAFStatusNameWithColor[taskRow.TimeChangeRequestStatus]
    )
      ? RAFStatusNameWithColor[taskRow.TimeChangeRequestStatus]
      : null;

  const clientOverviewContent = () => {
    if (isNotNullAndUndefined(props.careRecipientRow)) {
      return (
        <div>
          <div className="raf_list_group">
            <CareProfileContentDetails
              careRecipientUID={props.careRecipientUID}
              careRecipientName={props.careRecipientName}
              relatedToType={relatedToType}
              currentObject={props.careRecipientRow}
              paddingClass="pt-3"
              showTimeSheetAndShiftSummary={false}
              showClientNotesContent={false}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };
  const clientNoteContent = () => {
    return (
      <div style={{ cursor: "pointer" }}>
        {/* <div className="col-12 mb-1">
          <span className="subtitle_3 mb-2">PINNED NOTES</span>
        </div>  */}
        <ServiceTransactionNotes
          careRecipientUID={props.careRecipientUID}
          careRecipientName={props.careRecipientName}
          relatedToType={relatedToType}
          // {...(BrowserIsDevice ? { viewMode: "Horizontal" } : {})}
        />
      </div>
    );
  };

  const billingContent = () => {
    return (
      <InvoiceBillingListCardContent
        shiftUID={taskRow.UID}
        serviceTransaction={taskRow}
      />
    );
  };

  const timeSheetContent = () => {
    return <PayRollTimeSheetListCardContent shiftUID={taskRow.UID} />;
  };

  const timelineContent = () => {
    return (
      <div className="subtitle_2 py-3">
        <CareAuditList relatedToUID={taskRow.UID} />
      </div>
    );
  };

  const onTabSelected = (selectedTabValue?) => {
    setState({ selectedTabValue: selectedTabValue });
  };

  const clientleftCardContent = () => {
    return (
      <div className="pt-3">
        <ServiceTransactionProfileInfoHeader
          careRecipientUID={taskRow.RelatedToUID}
          careRecipientModule={taskRow.RelatedToType}
          displayType="ProfileInfoHeader"
          careRecipientRow={props.careRecipientRow}
        />
      </div>
    );
  };

  const renderTabContent = () => {
    if (CareEsioEntity.CarePlan.DisplayName === state.selectedTabValue.text) {
      // return clientOverviewContent();
      return carePlanDetailsContent();
    }

    if (state.selectedTabValue.text === "Client Profile") {
      return <div className="p-2 p-md-3">{clientleftCardContent()}</div>;
    }
    // if (state.selectedTabValue.text === CareEsioEntity.CarePlan.DisplayName) {
    //   return carePlanDetailsContent();
    // }
    if (
      state.selectedTabValue.text ===
        `${RAFServiceTransactionType.Booking} Log` ||
      state.selectedTabValue.text === "Shift Log"
    ) {
      return <div className="p-2 p-md-3">{careShiftLogTabContent()}</div>;
    }
    //if (state.selectedTabValue.text === CareEsioTab.Billing.CollectionName) {//commented by hari
    if (state.selectedTabValue.text === "Invoice Items") {
      return <div className="p-2 p-md-3">{billingContent()}</div>;
    }
    if (state.selectedTabValue.text === CareEsioTab.Payroll.CollectionName) {
      return <div className="p-2 p-md-3">{timeSheetContent()}</div>;
    }
    if (
      state.selectedTabValue.text ===
      CareEsioEntity.CareRecipientAuditTimeLine.CollectionName
    ) {
      return <div className="p-2 p-md-3">{timelineContent()}</div>;
    }
    if (state.selectedTabValue.text === "Previous Log") {
      return previousHistoryContent();
    }
  };

  const carePlanDetailsContent = () => {
    const { careRecipientRow } = props;
    return (
      <CarePlanDetails
        careRecipientUID={careRecipientRow["UID"]}
        careRecipientName={careRecipientRow["recipient_name"]}
        careRecipientRow={careRecipientRow}
        uimode="Tab"
      />
    );
  };

  const shiftLogContent = () => {
    let selectedTabValue = state.selectedTabValue;
    if (state.showShiftLogContent === true) {
      return renderTabContent();
    } else {
      return <></>;
    }
  };

  const previousHistoryContent = () => {
    return (
      <ServiceTransactionPreviousLogPage
        serviceTransactionUID={taskRow.UID}
        careRecipientUID={props.careRecipientUID}
        careRecipientName={props.careRecipientName}
        defaultServiceTransactionType={taskRow.Type}
        defaultShiftEndDate={taskRow.StartDate}
        isDashBoard={false}
      />
    );
  };

  const toggleDrawer = (isOpen) => {
    setState({ showDrawerContent: isOpen });
  };

  const openDrawer = () => {
    setState({ showDrawerContent: true });
  };

  const closeDrawer = () => {
    setState({ showDrawerContent: false });
  };

  const clientOverviewDlgContent = () => {
    const actualStartDateTime = getFormatedDate(
      taskRow.ActualStartDate,
      "hh:mm a"
    );
    const actualEndDateTime = getFormatedDate(taskRow.ActualEndDate, "hh:mm a");
    return (
      <div className="e-dlg-content-outer">
        <div className="e-dlg-body-content">
          <div className="row gx-0 gy-3">
            <div className="col-12">
              {careTaskSectionAssigneeStartEndContent()}
            </div>
            {taskRow.Type === RAFServiceTransactionType.Shift && (
              <div className="col-12">
                {careTaskStartAndEndTimeContent(
                  actualStartDateTime,
                  actualEndDateTime
                )}
              </div>
            )}
          </div>
        </div>
        {clientOverviewDlgFooter()}
      </div>
    );
  };
  const clientOverviewDlgFooter = () => {
    return (
      <div className="e-dlg-footerContent pb-3">
        <div className="w-100">
          <div className="row gx-2 justify-content-between">
            {/* <div className="col-auto">
              {deleteBtnContent(false)}
            </div> */}
            <div className="col-auto">{editBtnContent(false)}</div>
          </div>
        </div>
      </div>
    );
  };
  const clientOverviewDlgOpen = () => {
    setState({ showClientOverviewDlg: true });
  };
  const clientOverviewDlgClose = () => {
    setState({ showClientOverviewDlg: false });
  };

  //request time change end

  return (
    <div className="col-12" key={taskRow.UID}>
      {BrowserIsDevice && (
        <div
          className="care_task_section_footer"
          // onClick={() => toggleDrawer(true)}
        >
          {careTaskTitleSection()}
        </div>
      )}
      <div
        className="p-2 p-md-3"
        onClick={() => {
          if (!isDetailsMode) {
            onDetailsClicked();
          }
        }}
      >
        <div className="row gx-0 gy-3">
          {!BrowserIsDevice ? (
            <div className="col-12">{careTaskSectionCustomTitle()}</div>
          ) : (
            ""
          )}
          {state.showShiftLogContent === true &&
          IsNotNullOrWhiteSpace(taskRow.Description) ? (
            <div className="col-12">
              <CustomCardWidget cardContentClassName="p-md-3 p-2">
                <div>
                  <span className="body_3 medium mb-2">Shift Instructions</span>
                </div>
                <CustomCardWidget
                  cardClassName="mt-1 surface_neutral_base"
                  cardContentClassName="p-2"
                >
                  <span
                    className="description-text body_2 regular"
                    dangerouslySetInnerHTML={{
                      __html:
                        taskRow !== null
                          ? DOMPurify.sanitize(taskRow.Description)
                          : undefined,
                    }}
                  ></span>
                </CustomCardWidget>
              </CustomCardWidget>
            </div>
          ) : (
            ""
          )}
          {props.isDashBoard && (
            <div className="col-12">{clientNoteContent()}</div>
          )}
        </div>
      </div>
      <div className="">
        <div id="raf_details_page_tab_header_container" className="w-auto">
          <div
            id="raf_details_page_tab_header"
            className="raf_tab_header hideScrollBar raf_lg surface_neutral_base"
          >
            {isNotEmptyArray(headertext)
              ? headertext.map((item, index) => {
                  const permission =
                    isNotNullAndUndefined(item.permissionName) &&
                    isNotNullAndUndefined(permissionValue)
                      ? hasPermission(permissionValue, item.permissionName)
                      : true;
                  if (permission) {
                    return (
                      <div
                        className={`ms-0 raf_tab_header_item${
                          (isNotNullAndUndefined(state.selectedTabValue) &&
                            state.selectedTabValue.text === item.text) ||
                          (isNullOrUndefined(state.selectedTabValue.text) &&
                            index === 0)
                            ? " active"
                            : ""
                        }`}
                        key={`${item.text}_${index}`}
                        onClick={() => onTabSelected(item)}
                      >
                        <span>{item.text}</span>
                      </div>
                    );
                  }
                })
              : ""}
          </div>
        </div>
      </div>
      <div className="">{shiftLogContent()}</div>
      {false && (
        <CustomCardWidget
          cardClassName={`overflow-hidden hover-parent-div${
            !isListView ? " border-0" : " surface_neutral_base"
          }`}
          cardContentClassName={
            !isListView
              ? `${BrowserIsDevice ? "p-0" : "p-0 py-2 py-md-3"}`
              : "p-2 p-md-3"
          }
          onClick={() => {
            if (!isDetailsMode) {
              onDetailsClicked();
            }
          }}
        >
          <div className="px-2 px-md-3">
            {!BrowserIsDevice ? careTaskSectionCustomTitle() : ""}
            {state.showShiftLogContent === true &&
            IsNotNullOrWhiteSpace(taskRow.Description) ? (
              <CustomCardWidget
                cardClassName={!isListView ? "mt-3" : "border-0"}
                cardContentClassName="p-md-3 p-2"
              >
                <div>
                  <span className="body_3 medium mb-2">Shift Instructions</span>
                </div>
                <CustomCardWidget
                  cardClassName="mt-1 surface_neutral_base"
                  cardContentClassName="p-2"
                >
                  <span
                    className="description-text body_2 regular"
                    dangerouslySetInnerHTML={{
                      __html:
                        taskRow !== null
                          ? DOMPurify.sanitize(taskRow.Description)
                          : undefined,
                    }}
                  ></span>
                </CustomCardWidget>
              </CustomCardWidget>
            ) : (
              ""
            )}
            {props.isDashBoard && <div>{clientNoteContent()}</div>}
          </div>
          {shiftLogContent()}
          {BrowserIsDevice && (
            <SwipeableDrawer
              anchor={"top"}
              open={state.showDrawerContent}
              onClose={closeDrawer}
              onOpen={openDrawer}
            >
              <CustomCardWidget>
                {careTaskSectionCustomTitle()}
              </CustomCardWidget>
            </SwipeableDrawer>
          )}
        </CustomCardWidget>
      )}
      <div>
        {state.showClientOverviewDlg === true && (
          <DialogComponent
            header={`${taskRow.RelatedTo}`}
            showCloseIcon
            visible={state.showClientOverviewDlg}
            cssClass="centerDialog-md createEditForm full-height form-center-dialog dlg-new-style fixed-header"
            content={clientOverviewDlgContent}
            isModal
            target="body"
            closeOnEscape={false}
            close={clientOverviewDlgClose}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
            // footerTemplate={clientOverviewDlgFooter}
          />
        )}
      </div>
    </div>
  );
}

export default React.memo(CareTaskSection);
