import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import {
  ListServiceRequest,
  ShiftVariantRequest,
} from "../../../RAFComponents/models/Common/ListRequest";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { ContentType } from "../../../constants/Common/Constants";
import { ServiceContractItemRow } from "./ServiceContractItem";

export const getRelatedRecordByServiceContractId = (
  serviceContractId: string,
  sortQuery?: string[]
) => {
  return new Promise<ServiceContractItemRow[]>((resolve) => {
    if (isNotNullAndUndefined(serviceContractId)) {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = customFilter;
      listServiceRequest.Take = 1000;
      listServiceRequest.Skip = 0;

      if (isNotEmptyArray(sortQuery)) {
        listServiceRequest.Sort = sortQuery;
      }

      let additionalParams = [
        {
          key: "RelatedField",
          value: "ServiceContractUID",
        },
        {
          key: "RelatedFieldValue",
          value: serviceContractId,
        },
      ];

      if (
        isNotNullAndUndefined(additionalParams) &&
        additionalParams.length > 0
      ) {
        additionalParams.forEach((objProp) => {
          listServiceRequest[objProp.key] = objProp.value;
        });
      }

      if (isNotEmptyArray(sortQuery)) {
        listServiceRequest.Sort = sortQuery;
      }

      if (isNotNullAndUndefined(customFilter)) {
        listServiceRequest.CustomFilter = customFilter;
      }

      repositoryActions
        .postDataAndGetResponse(
          "ServiceContractItem/RelatedList",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (isNotNullAndUndefined(response.data)) {
              if (isNotNullAndUndefined(response.data.Entity)) {
                resolve(response.data.Entity);
              } else if (isNotEmptyArray(response.data.ResultTable)) {
                resolve(response.data.ResultTable);
              } else if (isNotNullAndUndefined(response.data.Entities)) {
                resolve(response.data.Entities);
              } else {
                resolve(null);
              }
            }
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};

export const getChildServiceContractItemCountByCareRecipientUID = (
  careRecipientUID: string
) => {
  return new Promise<number>((resolve) => {
    if (isNotNullAndUndefined(careRecipientUID)) {
      repositoryActions
        .postDataAndGetResponse(
          "ServiceContractItem/ChildSummary",
          { CareRecipientUID: careRecipientUID },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (isNotNullAndUndefined(response.data)) {
              resolve(response.data.TotalRows);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};

export const getServiceContractItemVariant = (
  serviceContractId: string,
  startDate: Date,
  endDate: Date,
  shiftType
) => {
  return new Promise<ServiceContractItemRow>((resolve) => {
    if (isNotNullAndUndefined(serviceContractId)) {
      let shiftVariantRequest = new ShiftVariantRequest();
      shiftVariantRequest.ServiceContractItemUID = serviceContractId;
      shiftVariantRequest.StartDate = startDate;
      shiftVariantRequest.EndDate = endDate;
      if (isNotNullAndUndefined(shiftType)) {
        shiftVariantRequest.ShiftType = shiftType;
      }

      repositoryActions
        .postDataAndGetResponse(
          "ServiceContractItem/GetVariant",
          shiftVariantRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entity)
          ) {
            resolve(response.data.Entity);
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};

export const getAllTravelVariantLookUpItems = (
  serviceContractItemUID: string,
  shiftType: string
) => {
  return new Promise<LookUpRow[]>((resolve) => {
    if (isNotNullAndUndefined(serviceContractItemUID)) {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];
      if (isNotNullAndUndefined(shiftType)) {
        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push(shiftType);
        filter.Operator = RAFCustomOperator.Equal;
        filter.Value = filterVal;
        filter.Field = "ShiftType";
        customFilter.Rules.push(filter);
      }

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;
      listServiceRequest.CustomFilter = customFilter;
      listServiceRequest.EntityName =
        CareEsioEntity.CareServiceContractItem.EntityName;
      listServiceRequest["ParentUID"] = serviceContractItemUID;

      return repositoryActions
        .postDataAndGetResponse(
          "ServiceContractItem/TravelVariantLookup",
          listServiceRequest,
          null,
          ContentType.applicationJson,
          false
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};

export const DeleteServiceContractItemRecord = (entityId: any) => {
  return new Promise<Boolean>((resolve) => {
    if (isNotNullAndUndefined(entityId)) {
      return repositoryActions
        .postDataAndGetResponse(
          `ServiceContractItem/Delete`,
          {
            EntityId: entityId,
          },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          let isDeleted = false;
          let message = "";
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            isDeleted = response.data[0].IsDeleted;
            message = response.data[0].Message;
          }

          if (isDeleted) {
            showSuccessToast(
              "Service Contract Item has been deleted successfully."
            );
            resolve(isDeleted);
          } else {
            if (IsNotNullOrWhiteSpace(message)) {
              showWarningToast(message);
            }
            resolve(isDeleted);
          }
        });
    } else {
      resolve(false);
    }
  });
};

export const getPricelistItemCustomFilter = (value) => {
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];
  if (IsNotNullOrWhiteSpace(value)) {
    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(value);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = "PricelistUID";
    relatedFilter.Rules.push(filter1);
  }

  let parentFilter: RAFCustomFilter = {};
  parentFilter.Operator = RAFCustomOperator.IsNull;
  parentFilter.Field = "ParentUID";
  relatedFilter.Rules.push(parentFilter);

  return relatedFilter;
};

export const getTravelItemById = (serviceContractId: string) => {
  return new Promise<ServiceContractItemRow>((resolve) => {
    if (isNotNullAndUndefined(serviceContractId)) {
      repositoryActions
        .postDataAndGetResponse(
          "ServiceContractItem/Retrieve",
          { EntityId: serviceContractId },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entity)
          ) {
            resolve(response.data.Entity);
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};
