import { DialogUtility } from "@syncfusion/ej2-popups";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";

import RAFCheckBox from "../../../RAFComponents/Inputs/RAFCheckBox";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  isNotNullAndUndefined
} from "../../../RAFComponents/helpers/utils";
import ACTextArea from "../../../components/shared/ACFormFields/ACTextArea";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import {
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { MyTeamsContext } from "../../Common/Providers/MyTeamsContextProvider";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import { UserGroupRow } from "./UserGroupRow";
import { saveUserGroupAPI } from "./UserGroupServiceHelper";

interface IProps {
  initialValues?: UserGroupRow;
  onDelete?: () => void;
  objectUID?: string;
  onClose?: () => void;
  onSave?: (entityId?: string, objectName?: string) => void;
  isActive: boolean;
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  userGroupRow: UserGroupRow;
}

function ManageUserGroup({
  ...props
}: PropsWithChildren<IProps>) {
  let rafForm: FormRenderProps | null;

  const myTeamsContext = useContext(MyTeamsContext);

  const moduleName = RAFEntityName.Team;

  const outerDivId = `manageupdate_userGroup_dialog`;
  let deleteDialog: any;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: true,
      userGroupRow: null,
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    if (props.isActive) {
      setState({ isLoading: true });
      const [userGroupRow] = await Promise.all([getIntitailUserGroupRow()]);

      if (isNotNullAndUndefined(userGroupRow)) {
        setState({ isLoading: false, noContent: false, userGroupRow });
      } else {
        setState({ isLoading: false, noContent: true });
      }
    }
  };

  const getIntitailUserGroupRow = () => {
    return new Promise<UserGroupRow>(async (resolve) => {
      if (isNotNullAndUndefined(props.objectUID)) {
        const userGroupRow = await RetrieveRecord(props.objectUID, moduleName);
        if (
          isNotNullAndUndefined(userGroupRow) &&
          isNotNullAndUndefined(userGroupRow.UID)
        ) {
          resolve(userGroupRow);
        } else {
          resolve(null);
        }
      } else {
        let initialObject = isNotNullAndUndefined(props.initialValues)
          ? props.initialValues
          : new UserGroupRow();

        resolve(initialObject);
      }
    });
  };

  const onSubmitUserGroup = async (value: UserGroupRow) => {
    let progressDiv = showProgress(`#${outerDivId}`);
    const response = await saveUserGroupAPI(value);
    hideProgress(progressDiv);
    if (
      isNotNullAndUndefined(response) &&
      isNotNullAndUndefined(response.entityId)
    ) {
      if (isNotNullAndUndefined(myTeamsContext)) {
        myTeamsContext.updateMyTeamList();
      }
      if (isNotNullAndUndefined(props.onSave)) {
        props.onSave(response.entityId);
      }
    } else {
      showWarningToast("Sorry something went wrong !");
    }
  };

  //delete item start
  const onClickDelete = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      title: `Delete Team`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    let progressDiv = showProgress(".deleteDialog.e-dialog");

    let isDeleted = await DeleteRecord(props.objectUID, moduleName);

    hideProgress(progressDiv);
    deleteDialog.hide();
    if (isDeleted) {
      if (isNotNullAndUndefined(myTeamsContext)) {
        myTeamsContext.updateMyTeamList();
      }
      if (isNotNullAndUndefined(props.onDelete)) {
        props.onDelete();
      } else if (isNotNullAndUndefined(props.onSave)) {
        props.onSave();
      }
    } else {
      showWarningToast("Sorry something went wrong !");
    }
  }
  //delete item end

  if (props.isActive) {
    if (state.isLoading === false) {
      if (state.noContent === false) {
        return (
          <RAFEntityProvider moduleName={moduleName}>
            <RAFAttributeRelatedListProvider moduleName={moduleName}>
              <RAFForm
                type={UserGroupRow}
                initialValues={state.userGroupRow}
                formRef={(g) => (rafForm = g)}
                layout={RAFLayout.TwoColumnLayout}
                onSubmit={onSubmitUserGroup}
                className="h-100"
              >
                <div className="e-dlg-content-outer" id={outerDivId}>
                  <div className="e-dlg-body-content">
                    <div className="row gx-2 gy-4">
                      <ACTextBox<UserGroupRow> field="TeamName" label="Name" required placeholder="Name" />
                      <div className="col-12">
                        <RAFCheckBox
                          field={`IsCollaboration`}
                          label="Is Collaboration"
                          showLabel={true}
                          validate={false}
                          labelPosition="right"
                          inputFieldClassName="col-auto"
                          labelClassName="col"
                          rowClassName="row g-2 align-items-center"
                          hideRequiredMessage
                        ></RAFCheckBox>
                      </div>
                      <ACTextArea<UserGroupRow> field="Description" label="Description" placeholder="Description" />
                      <RAFDropdownCC
                        field="UsageType"
                        label="Usage Type"
                        moduleName={moduleName}
                        required />
                    </div>
                  </div>
                  <div className="e-dlg-footerContent ">
                    <div className="w-100">
                      <div className="row gx-2">
                        {isNotNullAndUndefined(props.objectUID) && (
                          <div className="col-auto">
                            <RAFButtonComponent
                              action={RAFButtonConstant.Delete}
                              onClick={() => onClickDelete()}
                              idString="DeleteContent"
                              className="e-danger e-outline"
                            />
                          </div>
                        )}
                        <div className="col-auto ms-auto">
                          <RAFButtonComponent
                            type="button"
                            isPrimary
                            action={RAFButtonConstant.Save}
                            onClick={() => rafForm && rafForm.form.submit()}
                            idString="CreateContent"
                            disabled={rafForm && rafForm.submitting}
                          />
                        </div>
                        <div className="col-auto">
                          <RAFButtonComponent
                            type="button"
                            action={RAFButtonConstant.Cancel}
                            onClick={props.onClose}
                            idString="CreateContent"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </RAFForm>
            </RAFAttributeRelatedListProvider>
          </RAFEntityProvider>
        );
      } else {
        return (
          <div className="container-fluid px-0">
            <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
          </div>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Loading..." />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}

export default React.memo(ManageUserGroup);
